/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-const-assign */
/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaLock } from "react-icons/fa";
import Baseurl from "../../../../../Baseurl";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import Swal from "sweetalert2";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FaCheckCircle, FaBars } from "react-icons/fa";
import { Progress } from "antd";
import GoogleSerach from "./GoogleSerach";
import GoogleSearchTwo from "./GoogleSearchTwo";
import GoogleSerachThree from "./GoogleSearchThree";

const TanPersonalInfo = () => {
  const [loader, setLoader] = useState(false);
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = JSON.parse(localStorage.getItem("userDetail"));
  let Id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", Id);

  const location = useLocation();
  const pers =
    location.pathname === "/heloc/tanent/personalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const addit =
    location.pathname === "/heloc/tanent/additionalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cosi =
    location.pathname === "/heloc/tanent/Co-Borrowers"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/heloc/tanent/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const back =
    location.pathname === "/heloc/tanent/background"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const demo =
    location.pathname === "/heloc/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const docu =
    location.pathname === "/heloc/tanent/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/heloc/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const postData = () => {
    try {
      const token = localStorage.getItem("usertoken");
      var config = {
        method: "post",
        url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
        data: reviewData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then((response) => {
          setAllPostData(response?.data?.data);
          console.log(response?.data?.data, "sidebar response");
          if (response?.data?.status === true) {
            console.log(response?.data?.message, "response?.data?.message");
            setLoader(false);

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          } else {
            setLoader(false);
          }
          // console.log(allGet, "all data");
          console.log(response, "my response");
        })
        .catch(function (error) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setBund(error?.response?.data?.errors);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };

  const [id, Setid] = useState();

  const [FirstName, setFirstName] = useState("");
  const [Middle, setMiddleName] = useState("");
  const [Last, setLastName] = useState("");
  const [altname, setAltname] = useState(0);
  const [Alt, setAlt] = useState("");
  const [nickname, setNickname] = useState(false);
  const [Nick, setNick] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [Phone, setPhone] = useState("");
  const [WorkPhone, setWorkPhone] = useState("");
  const [Ext, setExt] = useState("");
  const [HomePhone, setHomePhone] = useState("");
  const [address, setaddress] = useState("");
  const [address2, setaddress2] = useState("");
  const [mailing, setMailing] = useState(0);
  const [MailingAddress, setMailingAddress] = useState("");
  const [LandlordFirstName, setLandlordFirstName] = useState("");
  const [LandlordLastName, setLandlordLastName] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [LandlordPhone, setLandlordPhone] = useState("");
  const [LandlordContact, setLandlordContact] = useState("");
  const [EmergencyName, setEmergencyName] = useState("");
  const [EmergencyRelation, setEmergencyRelation] = useState("");
  const [EmergencyPhone, setEmergencyPhone] = useState("");
  const [Ssn, setSsn] = useState("");
  const [CreditScore, setCreditScore] = useState("");
  const [dateValueDate, setDateValueDate] = useState("");
  const [dateValueCheck, setDateCheck] = useState(false);
  const [Company, setCompany] = useState(false);
  const [CompanyValue, setCompanyValue] = useState("");
  const [multipleAddressData, setMultipleAddressData] = useState("");

  const [googleData, setGoogleData] = useState({
    street_number: "",
    street_name: "",
    city: "",
    state: "",
    postal_code: "",
    apt_unit: "",
  });

  const alternate_names = (e) => {
    if (e.target.checked) {
      setAltname(1);
    } else {
      setAltname(0);
    }
  };
  const history = useHistory();

  const [ApiData, setApiData] = useState();
  const get = () => {
    setLoader(true);
    let Id = localStorage.getItem("assignId");
    let token = localStorage.getItem("usertoken");

    const Data = new FormData();
    Data.append("application_id", Id);

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/personal/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config).then((response) => {
      setApiData(response?.data?.data);
      setFirstName(response?.data?.data?.user_profile?.first_name);
      setMiddleName(response?.data?.data?.middle_name);
      setLastName(response?.data?.data?.user_profile?.last_name);
      setAltname(response?.data?.data?.alternate_names);
      setAlt(response?.data?.data?.have_alternate_names);
      setNickname(response?.data?.data?.nick_name);
      setNick(response?.data?.data?.have_nick_name);
      setEmailID(response?.data?.data?.email);
      setPhone(response?.data?.data?.cell_phone);
      setWorkPhone(response?.data?.data?.work_phone);
      setExt(response?.data?.data?.ext);
      setHomePhone(response?.data?.data?.home_phone);
      setaddress2(response?.data?.data?.address2);
      setaddress(response?.data?.data?.address);
      setMailing(response?.data?.data?.mailing_address);
      setMailingAddress(response?.data?.data?.have_mailing_address);
      setCompany(response?.data?.data?.cheque);
      setEmergencyName(response?.data?.data?.first_name);
      setEmergencyRelation(response?.data?.data?.em_relation);
      setEmergencyPhone(response?.data?.data?.em_phone_no);
      setCreditScore(response?.data?.data?.credit_score);
      setLandlordFirstName(response?.data?.data?.landlord_first_name);
      setLandlordLastName(response?.data?.data?.landlord_last_name);
      setLandlordPhone(response?.data?.data?.landlord_phone_no);
      Setid(response?.data?.data?.id);
      setSsn(response?.data?.data?.ssn);
      setDateBirth(response?.data?.data?.landlord_date);
      setDateValueDate(response?.data?.data?.date_box);
      setDateCheck(response?.data?.data?.add_to_date);
      setLoader(false);
    });
  };

  useEffect(() => {
    get();
    postData();
  }, []);

  console.log(multipleAddressData, "multipleAddressData");

  const add = () => {
    setLoader(true);
    let Id = localStorage.getItem("assignId");
    let token = localStorage.getItem("usertoken");

    const Data = new FormData();
    Data.append("application_id", Id);
    Data.append("first_name", FirstName);
    Data.append("middle_name", Middle);
    Data.append("last_name", Last);
    Data.append("alternate_names", altname ? 1 : 0);
    Data.append("have_alternate_names", Alt == undefined ? "" : Alt);
    Data.append("nick_name", nickname ? 1 : 0);
    Data.append("have_nick_name", Nick == undefined ? "" : Nick);
    Data.append("email", EmailID);
    Data.append("cell_phone", Phone);
    Data.append("work_phone", WorkPhone);
    Data.append("ext", Ext);
    Data.append("home_phone", HomePhone);

    Data.append("mailing_address", mailing ? 1 : 0);
    Data.append(
      "address2",
      String(
        address2?.city +
          address2?.state +
          address2?.street_name +
          address2?.street_number +
          address2?.apt_unit +
          address2?.postal_code
      )
    );

    Data.append("cheque", Company ? 1 : 0);
    Data.append("landlord_first_name", LandlordFirstName);
    Data.append("landlord_last_name", LandlordLastName);
    Data.append("landlord_phone_no", LandlordPhone);
    Data.append("landlord_date", dateBirth);
    Data.append("add_to_date", dateValueCheck ? 1 : 0);
    Data.append("date_box", dateValueDate);

    Data.append(
      "landlord_communication",
      LandlordContact == "0"
        ? 0
        : LandlordContact == "1"
        ? 1
        : LandlordContact == 2
        ? 2
        : 0
    );
    Data.append("em_first_name", EmergencyName);
    Data.append("em_relation", EmergencyRelation);
    Data.append("em_phone_no", EmergencyPhone);
    Data.append("ssn", Ssn);
    Data.append("estimated_credit_score", CreditScore);

    Data.append(
      "address",
      String(
        googleData?.city ||
          "" + googleData?.state ||
          "" + googleData?.street_name ||
          "" + googleData?.street_number ||
          "" + googleData?.apt_unit ||
          "" + googleData?.postal_code ||
          ""
      )
    );
    Data.append("have_mailing_city", googleData?.city);
    Data.append("have_mailing_state", googleData?.state);
    Data.append("have_mailing_street", googleData?.street_name);
    Data.append("have_mailing_street_type", googleData?.street_number);
    Data.append("have_mailing_unit", googleData?.apt_unit);
    Data.append("have_mailing_zipcode", googleData?.postal_code);

    id && Data.append("id", id);

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/personal/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        console.log(response);
        setLoader(false);
        Swal.fire({
          toast: true,
          icon: "success",
          title: response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        history.push("/heloc/tanent/additionalinfo");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const handlePhoneNumberChange = (event, state) => {
    let inputPhoneNumber = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10); // truncate to 10 digits
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    state(formattedPhoneNumber);
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />

      <div className="container-fluid">
        <div className="row">
          <>
            <FaBars
              class=" none"
              onClick={() => {
                handleToggle();
              }}
            />

            <FaBars
              class=" block"
              id="topnav-hamburger-icon"
              onClick={() => {
                handleToggle();
              }}
            />
            <div
              className={
                isOpen === true
                  ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open "
                  : "d-none"
              }
            >
              <div className="px-4 my-3">
                <Link to="#">Dashboard</Link>
                <br />
                <span>Tanent</span>
                <Progress percent={allPostData} status="actice" />
              </div>
              <div className="tangreyline"></div>'
              <Link to="/heloc/tanent/personalinfo">
                <div className={pers}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/personalinfo" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Personal Info</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/additionalinfo"}>
                <div className={addit}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/additionalinfo" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Additional Info</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/cosigner"}>
                <div className={cosi}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/cosigner" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Co Singers</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/Income"}>
                <div className={inc}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/Income" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Income</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/background"}>
                <div className={back}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/background" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Background</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/demographic"}>
                <div className={demo}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/demographic" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Demographic</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/document"}>
                <div className={docu}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/document" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Document</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/review"}>
                <div className={review}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/review" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">
                    Review And Submit
                  </div>
                  <div></div>
                </div>
              </Link>
            </div>
          </>
          <div
            className={
              isOpen === true
                ? "col-md-8 open he mt-5 mb-2 ps-lg-5"
                : isOpen === false
                ? "col-md-12 open nhi he mt-5 mb-2 ps-lg-5"
                : ""
            }
          >
            <div className="form-group position-relative">
              <div className="row col-lg-9">
                <label
                  className="mb-3 p-md-0 form-label font-bold"
                  id="legal-name-left"
                >
                  Please fill your full, legal name
                </label>
                <div className="max-width-600 ">
                  <div className="input-group  vertical-on-mobile  ">
                    <div className="col-lg-3 col-md-3 wd8  p-md-0 position-relative">
                      <input
                        className="form-control text-capitalize  rounded-0 text-capitalize"
                        id="inputheight1"
                        type="text"
                        placeholder="First Name"
                        value={FirstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{ padding: "12px 10px", marginLeft: "15px" }}
                      />
                      {FirstName?.length > 4 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "10px",
                            bottom: "-2px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.first_name
                        ? bund?.first_name?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-lg-3 col-md-3 wd8 p-md-0">
                      <input
                        className="form-control text-capitalize  rounded-0 text-capitalize"
                        id="inputheight1"
                        type="text"
                        placeholder="Middle Name"
                        value={Middle}
                        onChange={(e) => setMiddleName(e.target.value)}
                        style={{ padding: "12px 10px", marginLeft: "15px" }}
                      />
                      {Middle?.length > 4 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "10px",
                            bottom: "-2px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.middle_name
                        ? bund?.middle_name?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-lg-3 col-md-3 wd8  p-md-0">
                      <input
                        className="form-control text-capitalize  rounded-0 text-capitalize"
                        id="inputheight1"
                        type="text"
                        placeholder="Last Name"
                        value={Last}
                        onChange={(e) => setLastName(e.target.value)}
                        style={{ padding: "12px 10px" }}
                      />
                      {Last?.length > 4 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "10px",
                            bottom: "-2px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.last_name
                        ? bund?.last_name?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="position-relative">
                      <div className="mt-5 d-flex align-items-baseline">
                        &nbsp; &nbsp;
                        <input
                          type="checkbox"
                          checked={altname == 1}
                          onChange={alternate_names}
                          value={altname}
                        />
                        <div className=""></div>
                        &nbsp; &nbsp;
                        <label className="alternate">
                          I have alternate names
                        </label>
                        <br />
                      </div>
                      {altname === 1 ? (
                        <input
                          style={{ padding: "10px 15px" }}
                          type="text"
                          className="form-control py-0 px-2 w-100 text-capitalize  my-3 text-capitalize"
                          id="inputfont1"
                          placeholder="Enter all names here sperated by commas"
                          value={Alt}
                          onChange={(e) => setAlt(e.target.value)}
                        />
                      ) : null}
                      {Alt?.length > 4 && altname === 1 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            left: "90%",
                            bottom: "-6px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_alternate_names
                        ? bund?.have_alternate_names?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="position-relative">
                      <div className="mt-5 d-flex align-items-baseline">
                        &nbsp; &nbsp;
                        <input
                          type="checkbox"
                          checked={nickname}
                          value={nickname}
                          onChange={() => setNickname(!nickname)}
                        />
                        &nbsp; &nbsp;
                        <label className="alternate">I have a nickname</label>
                      </div>

                      {nickname ? (
                        <input
                          style={{ padding: "12px 10px", width: "50%" }}
                          type="text"
                          className="form-control w-100 py-0 px-2 text-capitalize my-3 text-capitalize"
                          id="inputfont1"
                          placeholder="Enter nickname with no space"
                          value={Nick}
                          onChange={(e) => setNick(e.target.value)}
                        />
                      ) : null}
                      {Nick?.length > 4 && nickname ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            left: "90%",
                            bottom: "-6px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_nick_name
                        ? bund?.have_nick_name?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-9 position-relative">
                  <label className="form-label5 font-bold">
                    What is your date of birth?
                  </label>
                  <div className="prefix-suffix width-300">
                    <div className="position-relative">
                      <input
                        className="form-control ng-untouched ng-pristine ng-invalid date req-color78"
                        type="date"
                        value={dateBirth}
                        onChange={(e) => setDateBirth(e.target.value)}
                      />
                    </div>
                    {dateBirth?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "50px",
                          bottom: "-8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.landlord_date
                      ? bund?.landlord_date?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="form-group ng-star-inserted pl-md-3 widt">
                  <label className="form-label5 font-bold">
                    What is your Social Security Number?
                  </label>
                  <div className="prefix-suffix width-400 position-relative">
                    <input
                      className="form-control text-capitalize  mt-2 req-color7"
                      type="text"
                      placeholder="(000) 000-0000"
                      value={Ssn}
                      onChange={(e) => handlePhoneNumberChange(e, setSsn)}
                    />
                    {Ssn?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "16px",
                          bottom: "-8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                  </div>
                </div>

                {bund?.ssn
                  ? bund?.ssn.map((e) => <p className="text-danger">{e}</p>)
                  : null}
                <div className="alternate-name-wrapper mt-gutter width-400 ng-star-inserted">
                  <p className="small-text pl-md-3">
                    <FaLock className="text-success7" />
                    Your application and all personal information are safely
                    stored on secured encrypted servers
                  </p>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-10 position-relative">
                  <label className="form-label5 font-bold">
                    What is your estimated credit score?
                  </label>
                  <select
                    name="estimated_credit_score"
                    formcontrolname="creditRange"
                    className={`form-select ng-untouched ng-pristine ng-valid pl-2 py-2 ${
                      CreditScore === undefined ||
                      CreditScore === null ||
                      CreditScore === ""
                        ? "text-dark"
                        : "text-success"
                    }`}
                    value={CreditScore}
                    onChange={(e) => setCreditScore(e.target.value)}
                  >
                    <option selected>Select Credit Score</option>
                    <option value="780+" className="ng-star-inserted">
                      780+{" "}
                      {CreditScore === "780+" ? (
                        <span className="tick-mark2">✔</span>
                      ) : null}
                    </option>
                    <option value="740 - 779" className="ng-star-inserted">
                      740 - 779{" "}
                      {CreditScore === "740 - 779" ? (
                        <span className="tick-mark2">✔</span>
                      ) : null}
                    </option>
                    <option value="700 - 739" className="ng-star-inserted">
                      700 - 739{" "}
                      {CreditScore === "700 - 739" ? (
                        <span className="tick-mark2">✔</span>
                      ) : null}
                    </option>
                    <option value="660 - 699" className="ng-star-inserted">
                      660 - 699{" "}
                      {CreditScore === "660 - 699" ? (
                        <span className="tick-mark2">✔</span>
                      ) : null}
                    </option>
                    <option value="620 - 659" className="ng-star-inserted">
                      620 - 659{" "}
                      {CreditScore === "620 - 659" ? (
                        <span className="tick-mark2">✔</span>
                      ) : null}
                    </option>
                    <option value="Below 620" className="ng-star-inserted">
                      Below 620{" "}
                      {CreditScore === "Below 620" ? (
                        <span className="tick-mark2">✔</span>
                      ) : null}
                    </option>
                  </select>
                  {bund?.estimated_credit_score
                    ? bund?.estimated_credit_score.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>
              </div>

              <div className="mt-5 personalinfo_maxwidth position-relative">
                <h5 className="form-label font-bold text-capitalize">
                  Please fill your contact information
                </h5>
                <div className="w-100 row">
                  <div className="input-group w-50 col-md-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email ID"
                      formcontrolname="email"
                      className="form-control text-capitalize  text-lowercase req-color7 text-capitalize"
                      value={EmailID}
                      onChange={(e) => setEmailID(e.target.value)}
                    />
                    {EmailID?.length > 16 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "24px",
                          bottom: "-10px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.email
                      ? bund?.email.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                  <div className="input-group w-50 col-md-6">
                    <input
                      type="type"
                      name="phone"
                      formcontrolname="phone"
                      placeholder="(000) 000-0000"
                      className="form-control text-capitalize  text-lowercase req-color7"
                      value={Phone}
                      onChange={(e) => handlePhoneNumberChange(e, setPhone)}
                    />
                    {Phone?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "24px",
                          bottom: "-10px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.cell_phone
                      ? bund?.cell_phone?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>
                  <div className="input-group mt-2 w-50 col-md-6">
                    <input
                      type="tel"
                      formcontrolname="workPhoneNumber "
                      name="work_phone"
                      inputmode="decimal"
                      autocomplete="nope"
                      placeholder="(000) 000-0000"
                      className="form-control text-capitalize  req-color7"
                      id="workphone_input"
                      value={WorkPhone}
                      onChange={(e) => handlePhoneNumberChange(e, setWorkPhone)}
                    />
                    {WorkPhone?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "52%",
                          bottom: "-8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.work_phone
                      ? bund?.work_phone?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                    <input
                      placeholder="Optional (000) 000-0000"
                      formcontrolname="workExt"
                      name="ext"
                      inputmode="decimal"
                      className="form-control text-capitalize  max-width-100 req-color7"
                      value={Ext}
                      onChange={(e) => handlePhoneNumberChange(e, setExt)}
                    />
                    {Ext?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "24px",
                          bottom: "-8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.ext
                      ? bund?.ext?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>

                  <div className="input-group mt-2 w-50 col-md-6">
                    <input
                      formcontrolname="homePhoneNumber"
                      name="home_phone"
                      inputmode="decimal"
                      autocomplete="nope"
                      placeholder="(000) 000-0000"
                      className="form-control text-capitalize  req-color7"
                      value={HomePhone}
                      onChange={(e) => handlePhoneNumberChange(e, setHomePhone)}
                    />
                    {HomePhone?.length > 4 ? (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          position: "absolute",
                          color: "green",
                          right: "24px",
                          bottom: "-8px",
                          zIndex: "123",
                        }}
                      >
                        ✔
                      </p>
                    ) : null}
                    {bund?.home_phone
                      ? bund?.home_phone?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}
                  </div>

                  <div
                    className="font-xs text-dark font-medium mt-gutter-half clrh"
                    style={{
                      color: "#49545c",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    I confirm that I have read and agree to the{" "}
                    <a href="javascript:void(0);" class="">
                      {" "}
                      Consent{" "}
                    </a>{" "}
                    to contact <input type="checkbox" checked />
                  </div>
                  <div className="row mt-5 wid">
                    <h5 className="p-md-0 currently font-bold">
                      Where do you live currently?
                    </h5>

                    <GoogleSerach setGoogleData={setGoogleData} />
                    <div className="col-md-6 my-2 position-relative">
                      <input
                        className="form-control text-capitalize  max-width-500 pr-3 pac-target-input req-color7 text-capitalize"
                        type="text"
                        placeholder="Street"
                        value={
                          googleData?.street_name
                            ? googleData?.street_name
                            : "Street"
                        }
                      />
                      {googleData?.street_name?.length > 2 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_mailing_street
                        ? bund?.have_mailing_street?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-md-6 my-2 position-relative">
                      <input
                        className="form-control text-capitalize  max-width-500 pr-3 pac-target-input req-color7 text-capitalize"
                        type="text"
                        placeholder="Street Number"
                        value={
                          googleData?.street_number
                            ? googleData?.street_number
                            : "Street Type"
                        }
                      />
                      {googleData?.street_number?.length > 2 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_mailing_street_type
                        ? bund?.have_mailing_street_type?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-md-6 my-2 position-relative">
                      <input
                        className="form-control text-capitalize  max-width-500 pr-3 pac-target-input req-color7 text-capitalize"
                        type="text"
                        placeholder="State"
                        value={googleData?.state ? googleData?.state : "State"}
                      />
                      {googleData?.state?.length > 2 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_mailing_state
                        ? bund?.have_mailing_state?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-md-6 my-2 position-relative">
                      <input
                        className="form-control text-capitalize  max-width-500 pr-3 pac-target-input req-color7 text-capitalize"
                        type="text"
                        placeholder="City"
                        value={googleData?.city ? googleData?.city : "City"}
                      />
                      {googleData?.city?.length > 2 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_mailing_city
                        ? bund?.have_mailing_city?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-md-6 my-2 position-relative">
                      <input
                        className="form-control text-capitalize  max-width-500 pr-3 pac-target-input req-color7 text-capitalize"
                        type="text"
                        placeholder="Apt/unit"
                        value={
                          googleData?.apt_unit
                            ? googleData?.apt_unit
                            : "Apt/unit"
                        }
                      />
                      {googleData?.apt_unit?.length > 2 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_mailing_unit
                        ? bund?.have_mailing_unit?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>
                    <div className="col-md-6 my-2 position-relative">
                      <input
                        className="form-control text-capitalize  max-width-500 pr-3 pac-target-input req-color7 text-capitalize"
                        type="number"
                        placeholder="Zip Code"
                        value={
                          googleData?.postal_code
                            ? googleData?.postal_code
                            : "Zip Code"
                        }
                      />
                      {googleData?.postal_code?.length > 2 ? (
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            position: "absolute",
                            color: "green",
                            right: "24px",
                            bottom: "-8px",
                            zIndex: "123",
                          }}
                        >
                          ✔
                        </p>
                      ) : null}
                      {bund?.have_mailing_zipcode
                        ? bund?.have_mailing_zipcode?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                    </div>

                    <div className="mt-4 d-flex align-items-baseline mgn font-sm1 ">
                      &nbsp; &nbsp;
                      <input
                        type="checkbox"
                        checked={mailing}
                        onChange={() => setMailing(!mailing)}
                      />
                      {bund?.mailing_address
                        ? bund?.mailing_address?.map((e) => (
                            <p className="text-danger">{e}</p>
                          ))
                        : null}
                      &nbsp; &nbsp;
                      <label className="label font-sm  font-sm1  clr ">
                        My mailing address is the same as my current address - 5
                        years address needed
                      </label>
                    </div>
                    {!mailing && (
                      <div
                        className="iconss position-relative"
                        style={{ left: "0px" }}
                      >
                        <GoogleSearchTwo
                          setaddress2={setaddress2}
                          address2={address2}
                        />
                        <i className="prefix lg fa fa-search icon-top iconss2 "></i>
                        <i
                          className="suffix lg  fa fa-pencil pencil iconss7"
                          style={{ right: "20px" }}
                        ></i>
                      </div>
                    )}
                    {bund?.address2
                      ? bund?.address2?.map((e) => (
                          <p className="text-danger">{e}</p>
                        ))
                      : null}

                    <div>
                      <GoogleSerachThree
                        setMultipleAddressData={setMultipleAddressData}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <h5 className="h6">How far back do i need to go?</h5>
                  <p>
                    Landlords like to see where you have lived for at least the
                    last five years.
                  </p>

                  <p className="h5 text-black mb-4 font-bold">
                    Landlord Contact Info
                  </p>
                  <p className="h6">Is landlord a company or an individual.</p>
                  <p className="h6">To whom you send your cheques to?</p>

                  <span className="mt-3">
                    <input
                      type="radio"
                      name="company"
                      checked={Company}
                      onClick={() => setCompany(!Company)}
                    />
                    &nbsp; Company
                  </span>
                  <span className="ms-5">
                    <input
                      type="radio"
                      name="company"
                      onClick={() => setCompany(false)}
                    />
                    &nbsp; Indiviual
                  </span>
                  <div className="row">
                    <div className="col-lg-10">
                      <div className="row">
                        {Company ? (
                          <div className="col-md-12 position-relative">
                            <label id="land_fname">Company Name</label>
                            <input
                              className="height input-group req-color7 text-capitalize"
                              type="text"
                              placeholder="Company Name"
                              value={CompanyValue}
                              onChange={(e) => setCompanyValue(e.target.value)}
                            />
                            {CompanyValue?.length > 4 ? (
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  position: "absolute",
                                  color: "green",
                                  right: "28px",
                                  bottom: "-4px",
                                  zIndex: "123",
                                }}
                              >
                                ✔
                              </p>
                            ) : null}
                            {/* {bund?.landlord_first_name
                            ? bund?.landlord_first_name?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null} */}
                          </div>
                        ) : null}
                        <div className="col-md-6 position-relative">
                          <label id="land_fname">First Name</label>
                          <input
                            className="height input-group req-color7 text-capitalize"
                            type="text"
                            placeholder="First Name"
                            value={LandlordFirstName}
                            onChange={(e) =>
                              setLandlordFirstName(e.target.value)
                            }
                          />
                          {LandlordFirstName?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-4px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.landlord_first_name
                            ? bund?.landlord_first_name?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                        <div className="col-md-6 position-relative">
                          <label id="land_fname">Last Name</label>
                          <input
                            className="height input-group req-color7 text-capitalize"
                            type="text"
                            placeholder="Last Name"
                            value={LandlordLastName}
                            onChange={(e) =>
                              setLandlordLastName(e.target.value)
                            }
                          />
                          {LandlordLastName?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-4px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.landlord_last_name
                            ? bund?.landlord_last_name?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="mt-4 d-flex align-items-baseline mgn font-sm1 ">
                          &nbsp; &nbsp;
                          <input
                            type="checkbox"
                            checked={
                              dateValueCheck == 1 || dateValueCheck
                                ? true
                                : false
                            }
                            value={dateValueCheck}
                            onClick={() => setDateCheck(!dateValueCheck)}
                          />
                          &nbsp; &nbsp;
                          <label className="label font-sm  font-sm1  clr ">
                            Check box for present and add to date box here
                          </label>
                        </div>

                        {dateValueCheck ? (
                          <div className="col-md-6 position-relative">
                            <label id="land_fname">Date</label>
                            <div className="prefix-suffix width-300">
                              <div className="position-relative">
                                <input
                                  className="form-control ng-untouched ng-pristine ng-invalid date req-color78 py-4"
                                  type="date"
                                  value={dateValueDate}
                                  onChange={(e) =>
                                    setDateValueDate(e.target.value)
                                  }
                                />
                              </div>
                              {dateValueDate?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    color: "green",
                                    right: "50px",
                                    bottom: "-3px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                          </div>
                        ) : null}

                        <div className="col-md-6 position-relative">
                          <label id="land_fname">Phone Number</label>
                          <input
                            className="height input-group req-color7"
                            type="tel"
                            placeholder="(949) 298-1501"
                            value={LandlordPhone}
                            onChange={(e) =>
                              handlePhoneNumberChange(e, setLandlordPhone)
                            }
                          />
                          {LandlordPhone?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-4px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.landlord_phone_no
                            ? bund?.landlord_phone_no?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                      </div>

                      <br />

                      <hr />

                      <div className="row mt-5">
                        <p className="h5 text-black font-bold">
                          Emergency Contact
                        </p>
                        <div className="col-md-6 mt-2 position-relative">
                          <label id="land_fname">Full Name</label>
                          <input
                            className="form-control text-capitalize  req-color7 height3 text-capitalize"
                            type="text"
                            placeholder="Full Name"
                            value={EmergencyName}
                            onChange={(e) => setEmergencyName(e.target.value)}
                          />
                          {EmergencyName?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-2px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.em_first_name
                            ? bund?.em_first_name?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                        <div className="col-md-6 mt-2 position-relative">
                          <label id="land_fname">Relationship</label>
                          <input
                            className="form-control text-capitalize  req-color7 height3 text-capitalize"
                            type="text"
                            placeholder="Friend"
                            value={EmergencyRelation}
                            onChange={(e) =>
                              setEmergencyRelation(e.target.value)
                            }
                          />
                          {EmergencyRelation?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-2px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.em_relation
                            ? bund?.em_relation?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                        <div className="col-md-6 mt-2 position-relative">
                          <label id="land_fname">Phone Number</label>
                          <input
                            className="form-control text-capitalize  req-color7 height3"
                            type="tel"
                            placeholder="(949) 298-1501"
                            value={EmergencyPhone}
                            onChange={(e) =>
                              handlePhoneNumberChange(e, setEmergencyPhone)
                            }
                          />
                          {EmergencyPhone?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "0px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.em_phone_no
                            ? bund?.em_phone_no?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <label className="text-scolor fontsbtn text-center">
                    Next is{" "}
                    <span className="fontsbtncobor">
                      Additional Information
                    </span>
                  </label>
                  <br />
                  <button
                    type="button"
                    className="btnn1 btnn-primary7 btn-lg"
                    onClick={add}
                  >
                    Save & Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>

                {/*  */}
              </div>
            </div>
            {/* <footer class="main-footer"> */}
            <div className="footerimage">
              <img
                src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
          <ProfileInfo />
        </div>
      </div>
    </>
  );
};

export default TanPersonalInfo;
