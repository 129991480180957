/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Baseurl from "../../../../../../Baseurl";
import Swal from "sweetalert2";
import axios from "axios";
import "./styles.css";

function Militry({ setpanel }) {
  // const history =  useHistory()

  const Assign_id = localStorage.getItem("assignId");
  // const [currentEmployee, setcurrentEmployee] = useState(false);
  // const [base_employment_income,Setbase_employment_income] = useState("")
  // const [military_employment_income, Setmilitary_employment_income] =
  //   useState(0);
  // const [base_employment_income, Setbase_employment_income] = useState(1);
  // const [other_income, Setother_income] = useState(0);
  // const [current, Setcurrent] = useState(0);
  const [primary, Setprimary] = useState(0);
  const [employee_name, SetEmployee_name] = useState("");
  const [start_date, SetStart_date] = useState("");
  const [current_emp, Set_current_emp] = useState(0);
  const [end_date, Set_end_date] = useState("");
  const [office_address, Set_office_address] = useState("");
  const [position, Setposition] = useState("");
  const [work_phone, Set_work_phone] = useState("");
  const [ext, Set_ext] = useState("");
  const [military_base_type, Setmilitary_base_type] = useState("");
  const [military_base, Setmilitary_base] = useState("");
  const [military_clothe_allowance_type, Set_military_clothe_allowance_type] =
    useState("");
  const [military_combat_pay_type, Set_military_combat_pay_type] = useState("");
  const [military_flight_pay_type, Set_military_flight_pay_type] = useState("");
  const [military_hazard_pay_type, Set_military_hazard_pay_type] = useState("");
  const [military_overseas_pay_type, Set_military_overseas_pay_type] =
    useState("");
  const [military_prop_pay_type, Set_military_prop_pay_type] = useState("");
  const [military_quarter_allowance_type, Set_military_quarter_allowance_type] =
    useState("");
  const [military_ration_allowance_type, Set_military_ration_allowance_type] =
    useState("");
  const [
    military_variable_housing_allowance_type,
    Set_military_variable_housing_allowance_type,
  ] = useState("");

  const [military_clothe_allowance, Set_military_clothe_allowance] =
    useState("");
  const [military_combat_pay, Set_military_combat_pay] = useState("");
  const [military_flight_pay, Set_military_flight_pay] = useState("");
  const [military_hazard_pay, Set_military_hazard_pay] = useState("");
  const [military_overseas_pay, Set_military_overseas_pay] = useState("");
  const [military_prop_pay, Set_military_prop_pay] = useState("");
  const [military_quarter_allowance, Set_military_quarter_allowance] =
    useState("");
  const [military_ration_allowance, Set_military_ration_allowance] =
    useState("");
  const [
    military_variable_housing_allowance,
    Set_military_variable_housing_allowance,
  ] = useState("");
  const [year_profession, Set_year_profession] = useState("");
  const [month_profession, Set_month_profession] = useState("");
  const [seasonal_income, Set_seasonal_income] = useState(0);
  const [foreign_income, Set_foreign_income] = useState(0);
  const [employed_family_member, Set_employed_family_member] = useState(0);

  const [co_Borrower, setCo_Borrower] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showfirstform, setshowfirstform] = useState(false);
  const [bund, setBund] = useState("");

  // const [income,Setincome] = usestate()

  const BorrowerData = new FormData();
  const Ext = ext?.replace(/\D/g, '');
  BorrowerData.append("base_employment_income", 0);
  BorrowerData.append("military_employment_income", 1);
  BorrowerData.append("other_income", 0);
  BorrowerData.append("application_id", Assign_id);
  BorrowerData.append("current", current_emp);
  BorrowerData.append("employee_name", employee_name);
  BorrowerData.append("start_date", start_date);
  BorrowerData.append("end_date", end_date);
  BorrowerData.append("office_address", office_address);
  BorrowerData.append("position", position);
  BorrowerData.append("work_phone", work_phone);
  BorrowerData.append("ext", Ext);
  BorrowerData.append("military_base_type", military_base_type);
  BorrowerData.append("military_base", military_base);
  BorrowerData.append(
    "military_clothe_allowance_type",
    military_clothe_allowance_type
  );
  BorrowerData.append("military_combat_pay_type", military_combat_pay_type);
  BorrowerData.append("primary", primary);

  BorrowerData.append("military_flight_pay_type", military_flight_pay_type);
  BorrowerData.append("military_hazard_pay_type", military_hazard_pay_type);
  BorrowerData.append("military_overseas_pay_type", military_overseas_pay_type);
  BorrowerData.append("military_prop_pay_type", military_prop_pay_type);
  BorrowerData.append(
    "military_quarter_allowance_type",
    military_quarter_allowance_type
  );
  BorrowerData.append(
    "military_quarter_allowance_type",
    military_quarter_allowance_type
  );
  BorrowerData.append(
    "military_ration_allowance_type",
    military_ration_allowance_type
  );
  BorrowerData.append(
    "military_variable_housing_allowance_type",
    military_variable_housing_allowance_type
  );
  BorrowerData.append("employed_family_member", employed_family_member);
  BorrowerData.append("military_clothe_allowance", military_clothe_allowance);

  BorrowerData.append("military_combat_pay", military_combat_pay);
  BorrowerData.append("military_flight_pay", military_flight_pay);
  BorrowerData.append("military_hazard_pay", military_hazard_pay);
  BorrowerData.append("military_overseas_pay", military_overseas_pay);
  BorrowerData.append("military_prop_pay", military_prop_pay);
  BorrowerData.append("military_quarter_allowance", military_quarter_allowance);
  BorrowerData.append("military_ration_allowance", military_ration_allowance);
  BorrowerData.append(
    "military_variable_housing_allowance",
    military_variable_housing_allowance
  );
  BorrowerData.append("year_profession", year_profession);
  BorrowerData.append("month_profession", month_profession);
  BorrowerData.append("seasonal_income", seasonal_income);
  BorrowerData.append("foreign_income", foreign_income);
  console.log(employee_name, "employee_name");

  const AddMilitryIncome = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/add/income`,
      data: BorrowerData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setCo_Borrower(response?.data?.message);
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setLoader(false);
          setshowfirstform(false);
          setpanel("");

          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setpanel("");
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const handleChange = (event) => {
    if (event.target.checked) {
      Set_current_emp(1);
    } else {
      Set_current_emp(0);
      // console.log(current_emp,"current_emp else")
    }
    // setIsSubscribed(current => !current);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  function handleSelectChangeForMilitryBase(event) {
    Setmilitary_base_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_clothe_allowance_type(event) {
    Set_military_clothe_allowance_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_combat_pay_type(event) {
    Set_military_combat_pay_type(event.target.value);
    console.log(event.target.value, "event.target.value combbat pay");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_flight_pay_type(event) {
    Set_military_flight_pay_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_hazard_pay_type(event) {
    Set_military_hazard_pay_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_overseas_pay_type(event) {
    Set_military_overseas_pay_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_prop_pay_type(event) {
    Set_military_prop_pay_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_quarter_allowance_type(event) {
    Set_military_quarter_allowance_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_ration_allowance_type(event) {
    Set_military_ration_allowance_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  function handleSelectChangeForMilitary_variable_housing_allowance_type(
    event
  ) {
    Set_military_variable_housing_allowance_type(event.target.value);
    console.log(event.target.value, "event.target.value");
    // geyEpisodeBySeason(event.target.value)
  }
  const handleChangeforEmployFamily = (event) => {
    if (event.target.checked) {
      Set_employed_family_member(1);
    } else {
      Set_employed_family_member(0);
      // console.log(current_emp,"current_emp else")
    }
    // setIsSubscribed(current => !current);
  };
  const handleChange0 = (event) => {
    if (event.target.checked) {
      Setprimary(1);
    } else {
      Setprimary(0);
      // console.log(current_emp,"current_emp else")
    }
    // setIsSubscribed(current => !current);
  };
  const handleChange2 = (event) => {
    if (event.target.checked) {
      Set_seasonal_income(1);
    } else {
      Set_seasonal_income(0);
      // console.log(current_emp,"current_emp else")
    }
    // setIsSubscribed(current => !current);
  };
  const handleChange3 = (event) => {
    if (event.target.checked) {
      Set_foreign_income(1);
    } else {
      Set_foreign_income(0);
      // console.log(current_emp,"current_emp else")
    }
    // setIsSubscribed(current => !current);
  };
  const onCancel = () => {
    setpanel("");
  };

  const handlePhoneNumberChange = (event, state) => {
    let inputPhoneNumber = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10); // truncate to 10 digits
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    state(formattedPhoneNumber);
  };

  const [editing, steediting] = useState(true);
  function handleChanges(event, state, type) {
    if (type === "price_of_property") {
      steediting(false);
    } else {
      steediting(false);
    }
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const formattedValue = Number(numericValue).toLocaleString();
    state(formattedValue);
  }

  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <h2 className="form-label">Military Employment Income</h2>
      <div className="d-flex" id="current-lft7">
        <div class="form-check alternate5">
          <input
            type="checkbox"
            class="form-check-label"
            onChange={handleChange}
          />{" "}
          Current
          {/* </label> */}
        </div>

        <div class="form-check ms-3 ms-md-5 alternate5">
          <input
            type="checkbox"
            class="form-check-label"
            onChange={handleChange0}
          />{" "}
          Primary
          {/* </label> */}
        </div>
      </div>

      <h6 className="mt-5">EMPLOYMENT DETAILS</h6>

      <form action="" onSubmit={handleSubmit} className="mt-gutter width-500 ">
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Employer Name
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color8"
            placeholder="Required"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => SetEmployee_name(e.target.value)}
          />
          {employee_name?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "21%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
        {bund?.employee_name
          ? bund?.employee_name?.map((e) => <p className="text-danger">{e}</p>)
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Start Date
          </span>
          <input
            type="date"
            class="form-control text-capitalize req-color8"
            placeholder="Required"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => SetStart_date(e.target.value)}
          />
          {start_date?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "24%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
        {bund?.start_date
          ? bund?.start_date.map((e) => <p className="text-danger">{e}</p>)
          : null}

        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            End Date
          </span>
          <input
            type="date"
            class="form-control text-capitalize req-color8"
            placeholder="Required"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => Set_end_date(e.target.value)}
          />
          {end_date?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "24%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
        {bund?.end_date
          ? bund?.end_date.map((e) => <p className="text-danger">{e}</p>)
          : null}
        {current_emp == 1 ? (
          <>
            <div class="input-group mb-3">
              <span class="input-group-text emp-wd4" id="basic-addon1">
                Month profession
              </span>
              <input
                type="number"
                class="form-control text-capitalize req-color7 req-color8"
                placeholder="Required"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => Set_month_profession(e.target.value)}
              />
              {month_profession?.length >= 7 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "21%",
                    bottom: "-8px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
            {bund?.month_profession
              ? bund?.month_profession.map((e) => (
                  <p className="text-danger">{e}</p>
                ))
              : null}
            <div class="input-group mb-3">
              <span class="input-group-text emp-wd4" id="basic-addon1">
                Year profession
              </span>{" "}
              <input
                type="number"
                class="form-control text-capitalize req-color7 req-color8"
                placeholder="Required"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => Set_year_profession(e.target.value)}
              />
              {year_profession?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "21%",
                    bottom: "-8px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
            {bund?.year_profession
              ? bund?.year_profession.map((e) => (
                  <p className="text-danger">{e}</p>
                ))
              : null}
          </>
        ) : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Office Address
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color8"
            placeholder="Required"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => Set_office_address(e.target.value)}
          />
          {office_address?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "21%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
        {bund?.end_date
          ? bund?.end_date.map((e) => <p className="text-danger">{e}</p>)
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Position
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color8"
            placeholder="Required"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => Setposition(e.target.value)}
          />
          {position?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "21%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
        {bund?.position
          ? bund?.position.map((e) => <p className="text-danger">{e}</p>)
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Work Phone
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color7 req-color12"
            placeholder="(xxx) xxx-xxxx"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={work_phone}
            onChange={(e) => handlePhoneNumberChange(e, Set_work_phone)}
          />
          {work_phone?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "51%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}

          <input
            type="text"
            class="form-control text-capitalize req-color7 req-color11 "
            placeholder="Ext."
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_ext, "ext");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${ext === undefined ? 0 : ext}${
              editing === true ? ".00" : ""
            }`}
          />
          {ext?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "21%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>

        <div className="d-flex justify-content-around">
          <div className="">
            {bund?.work_phone
              ? bund?.work_phone.map((e) => <p className="text-danger">{e}</p>)
              : null}
          </div>
          <div className="">
            {bund?.ext
              ? bund?.ext.map((e) => <p className="text-danger">{e}</p>)
              : null}
          </div>
        </div>

        <h6 className="my-5">EMPLOYMENT DETAILS</h6>

        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Militry Base
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Amount Required"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Setmilitary_base, "military_base");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_base === undefined ? 0 : military_base}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_base?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) => handleSelectChangeForMilitryBase(e)}
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_base
          ? bund?.military_base.map((e) => <p className="text-danger">{e}</p>)
          : null}
        {bund?.military_base_type
          ? bund?.military_base_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Clothes Allowance
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_clothe_allowance, "military_clothe_allowance");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_clothe_allowance === undefined ? 0 : military_clothe_allowance}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_clothe_allowance?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) =>
              handleSelectChangeForMilitary_clothe_allowance_type(e)
            }
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_clothe_allowance_type
          ? bund?.military_clothe_allowance_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}

        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Combat Pay
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_combat_pay, "military_combat_pay");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_combat_pay === undefined ? 0 : military_combat_pay}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_combat_pay?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          {bund?.military_combat_pay
            ? bund?.military_combat_pay.map((e) => (
                <p className="text-danger">{e}</p>
              ))
            : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) => handleSelectChangeForMilitary_combat_pay_type(e)}
          >
            <option value="2">ANNUALLY</option>
            <option value="3">MONTHLY</option>
          </select>
        </div>
        {bund?.military_combat_pay_type
          ? bund?.military_combat_pay_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}

        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Flight Pay
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_flight_pay, "military_flight_pay");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_flight_pay === undefined ? 0 : military_flight_pay}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_flight_pay?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          {bund?.military_flight_pay
            ? bund?.military_flight_pay.map((e) => (
                <p className="text-danger">{e}</p>
              ))
            : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) => handleSelectChangeForMilitary_flight_pay_type(e)}
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_flight_pay_type
          ? bund?.military_flight_pay_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}

        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Hazard Pay
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_hazard_pay, "military_hazard_pay");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_hazard_pay === undefined ? 0 : military_hazard_pay}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_hazard_pay?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) => handleSelectChangeForMilitary_hazard_pay_type(e)}
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_hazard_pay_type
          ? bund?.military_hazard_pay_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Overseas Pay
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_overseas_pay, "military_overseas_pay");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_overseas_pay === undefined ? 0 : military_overseas_pay}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_overseas_pay?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) => handleSelectChangeForMilitary_overseas_pay_type(e)}
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_overseas_pay_type
          ? bund?.military_overseas_pay_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Prop Pay
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_prop_pay, "military_prop_pay");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_prop_pay === undefined ? 0 : military_prop_pay}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_prop_pay?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) => handleSelectChangeForMilitary_prop_pay_type(e)}
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_prop_pay_type
          ? bund?.military_prop_pay_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Quarters Allowance
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_quarter_allowance, "military_quarter_allowance");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_quarter_allowance === undefined ? 0 : military_quarter_allowance}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_quarter_allowance?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) =>
              handleSelectChangeForMilitary_quarter_allowance_type(e)
            }
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_quarter_allowance_type
          ? bund?.military_quarter_allowance_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Rations Allowance
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_ration_allowance, "military_quarter_allowance");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_quarter_allowance === undefined ? 0 : military_quarter_allowance}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_ration_allowance?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) =>
              handleSelectChangeForMilitary_ration_allowance_type(e)
            }
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_ration_allowance_type
          ? bund?.military_ration_allowance_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div class="input-group mb-3">
          <span class="input-group-text emp-wd4" id="basic-addon1">
            Military Variable Housing Allowance
          </span>
          <input
            type="text"
            class="form-control text-capitalize req-color9 req-color7"
            placeholder="Optional"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleChanges(e, Set_military_variable_housing_allowance, "military_variable_housing_allowance");
            }}
            onBlur={() => steediting(true)}
            onFocus={() => steediting(false)}
            value={`${military_variable_housing_allowance === undefined ? 0 : military_variable_housing_allowance}${
              editing === true ? ".00" : ""
            }`}
          />
          {military_variable_housing_allowance?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "41%",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
          <select
            class="form-select req-color10"
            id="inputGroupSelect02"
            onChange={(e) =>
              handleSelectChangeForMilitary_variable_housing_allowance_type(e)
            }
          >
            <option value="ANNUALLY">ANNUALLY</option>
            <option value="MONTHLY">MONTHLY</option>
          </select>
        </div>
        {bund?.military_variable_housing_allowance_type
          ? bund?.military_variable_housing_allowance_type.map((e) => (
              <p className="text-danger">{e}</p>
            ))
          : null}
        <div className="d-flex mt-4 alternate5">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-label"
              onChange={handleChange2}
            />{" "}
            Seasonal Income
            {/* </label> */}
          </div>

          <div class="form-check ms-3 ms-md-5 alternate5">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Foreign"
              onChange={handleChange3}
            />
            <label class="form-check-label" for="Foreign">
              Foreign Income
            </label>
          </div>
        </div>

        <div class="form-check mt-4 alternate5">
          <input
            type="checkbox"
            class="form-check-label"
            onChange={handleChangeforEmployFamily}
          />{" "}
          Employed by a family member, property seller, real estate agent, etc.
        </div>
        <div className="d-flex my-4 ">
          <button
            className="btn btn-primary mx-2 w-100 save-wd4"
            onClick={AddMilitryIncome}
          >
            INVITE
          </button>
          <button
            className="btn btn-light mx-2 border w-100 close-wd4"
            onClick={onCancel}
          >
            CLOSE
          </button>
        </div>
      </form>
    </>
  );
}

export default Militry;
