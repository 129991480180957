/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../../Layout/Header";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../../../../Baseurl";
import { FaEdit, FaTrash, FaUserAlt } from "react-icons/fa";
import ProfileInfo from "../../Profile/ProfileInfo";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaCheckCircle, FaBars } from "react-icons/fa";
import { Progress } from "antd";

function TanCoSigner() {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const pers =
    location.pathname === "/heloc/tanent/personalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const addit =
    location.pathname === "/heloc/tanent/additionalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cosi =
    location.pathname === "/heloc/tanent/cosigner"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/heloc/tanent/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const back =
    location.pathname === "/heloc/tanent/background"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const demo =
    location.pathname === "/heloc/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const docu =
    location.pathname === "/heloc/tanent/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/heloc/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebarresponse");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePhoneNumberChange = (event, state) => {
    let inputPhoneNumber = event.target.value.replace(/\D/g, ""); // remove non-numeric characters
    if (inputPhoneNumber.length > 10) {
      inputPhoneNumber = inputPhoneNumber.slice(0, 10); // truncate to 10 digits
    }
    let formattedPhoneNumber = "";
    if (inputPhoneNumber.length > 3) {
      formattedPhoneNumber = `(${inputPhoneNumber.substring(0, 3)})`;
      if (inputPhoneNumber.length > 6) {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(
          3,
          6
        )}-${inputPhoneNumber.substring(6)}`;
      } else {
        formattedPhoneNumber += ` ${inputPhoneNumber.substring(3)}`;
      }
    } else {
      formattedPhoneNumber = inputPhoneNumber;
    }
    state(formattedPhoneNumber);
  };

  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };

  const Assign_id = localStorage.getItem("assignId");
  const [cob_last_name, setCob_last_name] = useState("");
  const [cob_email, setCob_email] = useState("");
  const [cob_phone, setCob_phone] = useState("");
  const [spouse, setSpouse] = useState(false);
  const [complete_task, setComplete_task] = useState(false);
  const [sp_first_name, setSp_first_name] = useState("");
  const [sp_last_name, setSp_last_name] = useState("");
  const [sp_email, setSp_email] = useState("");
  const [sp_phone, setSp_phone] = useState("");
  const [cob_first_name, setCob_first_name] = useState("");
  const [relationOne, setRelationOne] = useState("");
  const [relationTwo, setRelationTwo] = useState("");
  const [editFormShow, setEditFormShow] = useState(false);
  const [editId, setEditId] = useState("");

  const [showfirstform, setshowfirstform] = useState(false);
  // Getting All Co-Borrowers
  const [getborrower, setGetborrower] = useState("");
  const BorrowerDataGet = new FormData();
  BorrowerDataGet.append("application_id", Assign_id);
  const Get_Borrower = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/all/signers`,
      data: BorrowerDataGet,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setGetborrower(response?.data?.data);
        if (response?.data?.status === true) {
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
      });
  };
  useEffect(() => {
    Get_Borrower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Delete_Borrower = (id) => {
    const DelData = new FormData();
    DelData.append("application_id", Assign_id);
    DelData.append("id", id);
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/del/co-signer`,
      data: DelData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        if (response?.data?.status === true) {
          setLoader(false);
          Get_Borrower();
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const [solo_check, setSolo_check] = useState("");
  const [applyingsolo, setApplyingsolo] = useState(0);
  const Check_ApplyingSolo = (e) => {
    const value = e.target.value;
    const check = e.target.checked;

    if (check) {
      setSolo_check([...solo_check, value]);
      setApplyingsolo(1);
    } else {
      setSolo_check(solo_check.filter((e) => e !== value));
      setApplyingsolo(0);
    }
  };

  const Submit_Borrower = () => {
    const SubmitData = new FormData();
    SubmitData.append("application_id", Assign_id);
    SubmitData.append("applying_solely", applyingsolo);
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/co-signers/info`,
      data: SubmitData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        if (response?.data?.status === true) {
          setLoader(false);
          history.push("/heloc/tanent/Income");
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const Add_Borrower = () => {
    setLoader(true);
    // Function AIk Hi hai Ye Edit Or Add Ke Lye Jb User Edit Ke Arha Hai Too Me Aik State Me Aik State ko True Kr Rha huu Or Neche Ye condition Laga Raha Huu Ke Agar Woo State True Hai Too Edit Wali Api ChaleGi Wrna Add Wali Api CHaleGi
    const BorrowerData = new FormData();
    BorrowerData.append("cob_first_name", cob_first_name);
    BorrowerData.append("cob_last_name", cob_last_name);
    BorrowerData.append("cob_email", cob_email);
    BorrowerData.append("cob_phone", cob_phone);
    BorrowerData.append("sp_first_name", sp_first_name);
    BorrowerData.append("sp_last_name", sp_last_name);
    BorrowerData.append("sp_email", sp_email);
    BorrowerData.append("sp_phone", sp_phone);
    BorrowerData.append("spouse", spouse ? 1 : 0);
    BorrowerData.append("complete_task", complete_task ? 1 : 0);
    BorrowerData.append("relationship", relationOne);
    BorrowerData.append("sp_relationship", relationTwo);
    BorrowerData.append("application_id", Assign_id);
    if (editFormShow) {
      BorrowerData.append("id", editId);
    }
    let token = localStorage.getItem("usertoken");
    if (editFormShow) {
      const config = {
        method: "post",
        url: `${Baseurl.baseurl}real-estate/rent/tenant/invite/co-signers/info`,
        data: BorrowerData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          if (response?.data?.status === true) {
            setLoader(false);
            Get_Borrower();
            setshowfirstform(false);
            Swal.fire({
              toast: true,
              icon: "success",
              title: response?.data?.message,
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setEditFormShow(false);
          } else {
            setLoader(false);
            setEditFormShow(false);
          }
        })
        .catch(function (error) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setBund(error?.response?.data?.errors);
          Swal.fire({
            toast: true,
            icon: "error",
            title: error?.response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setEditFormShow(false);
        });
    } else {
      const config = {
        method: "post",
        url: `${Baseurl.baseurl}real-estate/rent/tenant/invite/co-signers/info`,
        data: BorrowerData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          if (response?.data?.status === true) {
            setLoader(false);
            Get_Borrower();
            setshowfirstform(false);
            Swal.fire({
              toast: true,
              icon: "success",
              title: response?.data?.message,
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setEditFormShow(false);
          } else {
            setLoader(false);
            setEditFormShow(false);
          }
        })
        .catch(function (error) {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          setBund(error?.response?.data?.errors);
          Swal.fire({
            toast: true,
            icon: "error",
            title: error?.response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        });
      setEditFormShow(false);
    }
  };

  const handlerEdit = (data) => {
    setshowfirstform(true);
    setEditFormShow(true);
    setCob_first_name(data?.cob_first_name);
    setCob_last_name(data?.cob_last_name);
    setCob_email(data?.cob_email);
    setCob_phone(data?.cob_phone);
    setSp_first_name(data?.sp_first_name);
    setSp_last_name(data?.sp_last_name);
    setSp_email(data?.sp_email);
    setSp_phone(data?.sp_phone);
    setComplete_task(data?.complete_task);
    setSpouse(data?.spouse);
    setRelationOne(data?.relationship);
    setRelationTwo(data?.sp_relationship);
    setEditId(data?.id);
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="container-fluid">
        <div className="row">
          <>
            <FaBars
              class=" none"
              onClick={() => {
                handleToggle();
              }}
            />

            <FaBars
              class=" block"
              id="topnav-hamburger-icon"
              onClick={() => {
                handleToggle();
              }}
            />
            <div
              className={
                isOpen === true
                  ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open "
                  : "d-none"
              }
            >
              <div className="px-4 my-3">
                <Link to="#">Dashboard</Link>
                <br />
                <span>Tanent</span>
                <Progress percent={allPostData} status="actice" />
              </div>
              <div className="tangreyline"></div>'
              <Link to="/heloc/tanent/personalinfo">
                <div className={pers}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/personalinfo" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Personal Info</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/additionalinfo"}>
                <div className={addit}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/additionalinfo" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Additional Info</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/cosigner"}>
                <div className={cosi}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/cosigner" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Co Signers</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/Income"}>
                <div className={inc}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/Income" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Income</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/background"}>
                <div className={back}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/background" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Background</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/demographic"}>
                <div className={demo}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/demographic" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Demographic</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/document"}>
                <div className={docu}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/document" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Document</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/heloc/tanent/review"}>
                <div className={review}>
                  <div className="sidecircle">
                    {location.pathname === "/heloc/tanent/review" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">
                    Review And Submit
                  </div>
                  <div></div>
                </div>
              </Link>
            </div>
          </>
          <div
            className={
              isOpen === true
                ? "col-md-8 open he mt-5 mb-2 ps-lg-5"
                : isOpen === false
                ? "col-md-10 open nhi he mt-5 mb-2 ps-lg-5"
                : ""
            }
          >
            <div className="row">
              <>
                <h3 className="mb-3 p-md-0 fw-bold" id="co-singers-h">
                  Co-Signers
                </h3>
                <p
                  class="ng-tns-c159-5  fontforsub fw-bold"
                  id="coborrowerstatus"
                >
                  No Co-Signer has been added yet
                </p>
                {getborrower ? (
                  getborrower?.map((e) => {
                    return (
                      <>
                        <div
                          className="row"
                          style={{
                            display: applyingsolo == 1 ? "none" : "flex",
                          }}
                        >
                          <div className="col-md-4 my-3">
                            <h4 className="text-muted">
                              <FaUserAlt className="text-primary" />
                              &nbsp;&nbsp;
                              {e?.cob_first_name}
                            </h4>
                            <h5 className="text-muted">
                              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                              {e?.cob_last_name}
                            </h5>
                            <h5 className="text-muted">
                              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                              {e?.cob_phone}
                            </h5>
                            <h5 className="text-muted">
                              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                              {e?.relationship}
                            </h5>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <div className="col-md-6 mt-2">
                                <FaEdit
                                  onClick={() => handlerEdit(e)}
                                  className="text-success"
                                  style={{
                                    fontSize: 25,
                                    cursor: "pointer",
                                  }}
                                />
                                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                <FaTrash
                                  onClick={() => Delete_Borrower(e?.id)}
                                  className="text-danger"
                                  style={{
                                    fontSize: 25,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p className=" fw-bold p-md-0">
                    No Co-Singer has been added yet
                  </p>
                )}

                {showfirstform === true ? (
                  <div className="mt-3 personalinfo_maxwidth">
                    <>
                      <label
                        className="form-label  text-capitalize"
                        id="information-h"
                      >
                        Fill Co-Singer's information
                      </label>
                      <div className="mt-4 contact_max position-relative">
                        <div class="input-group ">
                          <span
                            class="input-group-label contact-info-label "
                            id="first-name-width1"
                          >
                            First Name
                          </span>
                          <input
                            type="text"
                            name="fname"
                            placeholder="Required"
                            id="f-width1"
                            className="form-control text-capitalize  text-capitalize"
                            value={cob_first_name}
                            onChange={(e) => setCob_first_name(e.target.value)}
                          />
                          {cob_first_name?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "53%",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>

                        {bund?.cob_first_name
                          ? bund?.cob_first_name.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null}
                        <div class="input-group mt-2">
                          <span
                            class="input-group-label contact-info-label "
                            id="first-name-width1"
                          >
                            Last Name
                          </span>
                          <input
                            type="text"
                            placeholder="required"
                            id="f-width1"
                            className="form-control text-capitalize  text-capitalize"
                            value={cob_last_name}
                            onChange={(e) => setCob_last_name(e.target.value)}
                          />
                          {cob_last_name?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "53%",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>

                        {bund?.cob_last_name
                          ? bund?.cob_last_name.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null}
                        <div class="input-group mt-2">
                          <span
                            class="input-group-label contact-info-label "
                            id="first-name-width1"
                          >
                            Email ID
                          </span>
                          <input
                            type="email"
                            id="f-width1"
                            class="form-control"
                            placeholder="required"
                            value={cob_email}
                            onChange={(e) => setCob_email(e.target.value)}
                          />{" "}
                          {cob_email?.length > 16 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "53%",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>

                        {bund?.cob_email
                          ? bund?.cob_email.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null}
                        <div class="input-group mt-2">
                          <span
                            class="input-group-label contact-info-label "
                            id="first-name-width1"
                          >
                            Phone
                          </span>
                          <input
                            type="tel"
                            id="f-width1"
                            class="form-control rounded-0 input26clr"
                            placeholder="required"
                            onChange={(e) =>
                              handlePhoneNumberChange(e, setCob_phone)
                            }
                            value={cob_phone}
                          />{" "}
                          {cob_phone?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "53%",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>
                        {bund?.cob_phone
                          ? bund?.cob_phone.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null}
                        <div class="input-group mt-2">
                          <span
                            class="input-group-label contact-info-label "
                            id="first-name-width1"
                          >
                            RelationShip
                          </span>
                          <input
                            type="text"
                            id="f-width1"
                            class="form-control rounded-0 input26clr"
                            placeholder="RelationShip"
                            onChange={(e) => setRelationOne(e.target.value)}
                            value={relationOne}
                          />{" "}
                          {relationOne?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "53%",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>
                        {bund?.relationship
                          ? bund?.relationship?.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null}

                        <div class="form-check my-3">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={spouse ? true : false}
                            onChange={(e) => setSpouse(!spouse)}
                          />
                          <span
                            className="label font-sm1"
                            id="complete-fs1"
                            for="defaultCheck1"
                          >
                            This Singer is applying with his/her spouse
                          </span>{" "}
                        </div>

                        {bund?.spouse
                          ? bund?.spouse.map((e) => (
                              <p className="text-danger">{e}</p>
                            ))
                          : null}
                      </div>
                      {spouse ? (
                        <>
                          <label
                            className="form-label text-capitalize"
                            id="information-h"
                          >
                            Fill his/her spouse's information
                          </label>
                          <div className="mt-4 contact_max">
                            <div class="input-group ">
                              <span
                                class="input-group-label contact-info-label "
                                id="first-name-width1"
                              >
                                First Name
                              </span>
                              <input
                                type="text"
                                name="fname"
                                placeholder="Required"
                                id="f-width1"
                                value={sp_first_name}
                                className="form-control text-capitalize  text-capitalize"
                                onChange={(e) =>
                                  setSp_first_name(e.target.value)
                                }
                              />{" "}
                              {sp_first_name?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    color: "green",
                                    right: "53%",
                                    bottom: "-8px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>

                            {bund?.sp_first_name
                              ? bund?.sp_first_name.map((e) => (
                                  <p className="text-danger">{e}</p>
                                ))
                              : null}
                            <div class="input-group mt-2">
                              <span
                                class="input-group-label contact-info-label "
                                id="first-name-width1"
                              >
                                Last Name
                              </span>
                              <input
                                type="text"
                                name="text"
                                formcontrolname="email"
                                placeholder="required"
                                id="f-width1"
                                className="form-control text-capitalize  text-capitalize"
                                value={sp_last_name}
                                onChange={(e) =>
                                  setSp_last_name(e.target.value)
                                }
                              />
                              {sp_last_name?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    color: "green",
                                    right: "53%",
                                    bottom: "-8px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>

                            {bund?.sp_last_name
                              ? bund?.sp_last_name.map((e) => (
                                  <p className="text-danger">{e}</p>
                                ))
                              : null}
                            <div class="input-group mt-2">
                              <span
                                class="input-group-label contact-info-label "
                                id="first-name-width1"
                              >
                                Email ID
                              </span>
                              <input
                                formcontrolname="workPhoneNumber"
                                id="f-width1"
                                class="form-control"
                                type="email"
                                value={sp_email}
                                placeholder="required"
                                onChange={(e) => setSp_email(e.target.value)}
                              />
                              {sp_email?.length > 16 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    color: "green",
                                    right: "53%",
                                    bottom: "-8px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>

                            {bund?.sp_email
                              ? bund?.sp_email.map((e) => (
                                  <p className="text-danger">{e}</p>
                                ))
                              : null}
                            <div class="input-group mt-2">
                              <span
                                class="input-group-label contact-info-label "
                                id="first-name-width1"
                              >
                                Phone
                              </span>
                              <input
                                type="tel"
                                id="f-width1"
                                class="form-control rounded-0 input26clr"
                                placeholder="required"
                                onChange={(e) =>
                                  handlePhoneNumberChange(e, setSp_phone)
                                }
                                value={sp_phone}
                              />{" "}
                              {sp_phone?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    color: "green",
                                    right: "53%",
                                    bottom: "-8px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                            {bund?.sp_phone
                              ? bund?.sp_phone.map((e) => (
                                  <p className="text-danger">{e}</p>
                                ))
                              : null}
                            <div class="input-group mt-2">
                              <span
                                class="input-group-label contact-info-label "
                                id="first-name-width1"
                              >
                                RelationShip
                              </span>
                              <input
                                type="text"
                                id="f-width1"
                                class="form-control rounded-0 input26clr"
                                placeholder="RelationShip"
                                onChange={(e) => setRelationTwo(e.target.value)}
                                value={relationTwo}
                              />{" "}
                              {relationTwo?.length > 4 ? (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    color: "green",
                                    right: "53%",
                                    bottom: "-8px",
                                    zIndex: "123",
                                  }}
                                >
                                  ✔
                                </p>
                              ) : null}
                            </div>
                            {bund?.sp_relationship
                              ? bund?.sp_relationship?.map((e) => (
                                  <p className="text-danger">{e}</p>
                                ))
                              : null}

                            <div class="form-check my-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={complete_task ? true : false}
                                onChange={(e) =>
                                  setComplete_task(!complete_task)
                                }
                              />
                              <span
                                className="label font-sm1"
                                id="complete-fs1"
                                for="defaultCheck2"
                              >
                                This Singer's spouse can complete tasks on
                                his/her behalf Invite
                              </span>
                            </div>

                            {bund?.complete_task
                              ? bund?.complete_task.map((e) => (
                                  <p className="text-danger">{e}</p>
                                ))
                              : null}
                          </div>
                        </>
                      ) : null}
                      <div className="d-flex mt-4">
                        <button
                          className=" mr-4"
                          id="invitebtn1"
                          onClick={() => Add_Borrower()}
                        >
                          SAVE
                        </button>
                        <button
                          className="font-medium"
                          id="cancel-btn1"
                          onClick={() => setshowfirstform(false)}
                        >
                          CANCEL
                        </button>
                      </div>
                    </>
                  </div>
                ) : (
                  <>
                    <div className="personalinfo_property mt-2">
                      <input
                        label="Add Co-Signers"
                        type="radio"
                        id="buttonadd1"
                        className="btnn"
                        name="gender"
                        style={{
                          display: applyingsolo == 1 ? "none" : "block",
                        }}
                        defaultValue="Primary Residence"
                        onClick={() => {
                          setshowfirstform(true);
                          setCob_first_name("");
                          setCob_last_name("");
                          setCob_email("");
                          setCob_phone("");
                          setSp_first_name("");
                          setSp_last_name("");
                          setSp_email("");
                          setSp_phone("");
                          setComplete_task("");
                          setSpouse("");
                          setEditId("");
                          setRelationOne("");
                          setRelationTwo("");
                        }}
                      />
                    </div>
                    <div class="form-check my-3 solely-left1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                        onClick={Check_ApplyingSolo}
                      />
                      <label
                        class="form-check-label"
                        id="checkhover1"
                        for="defaultCheck1"
                      >
                        I am applying solely
                      </label>
                    </div>
                    <label className="fontsbtn">
                      Next is <span className="fontsbtncobor">Income</span>
                    </label>{" "}
                    <br />
                    <button
                      className="btn btn-primary rounded-0 mt-2 col-md-3 button-fw1"
                      onClick={() => Submit_Borrower()}
                    >
                      Save And Continue &nbsp;
                      <AiOutlineArrowRight />
                    </button>{" "}
                  </>
                )}
              </>
            </div>
          </div>
          <ProfileInfo />
          <div className="footerimage3">
            <img
              src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TanCoSigner;
