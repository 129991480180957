/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import HelSideBar from "../HelSidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaCheckCircle, FaBars } from "react-icons/fa";
import { Progress } from "antd";

const LanMortageinfo = () => {
  const location = useLocation();
  const mort =
    location.pathname === "/heloc/lanlord/mortgageinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const pers =
    location.pathname === "/personal_info"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cobo =
    location.pathname === "/Co-Borrowers"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const ass =
    location.pathname === "/assets"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const real =
    location.pathname === "/realstate"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const Decl =
    location.pathname === "/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const demo =
    location.pathname === "/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const credit =
    location.pathname === "/credit"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/review"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const [isOpen, setIsOpen] = useState(true);
  const [property, setProperty] = useState("");
  const [occupyProperty , setOccupyProperty] = useState('Primary Residence');
  const [currentMortage , setCurrentMortage] = useState('');

  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <>
            <FaBars
              class=" none"
              onClick={() => {
                handleToggle();
              }}
            />

            <FaBars
              class=" block"
              id="topnav-hamburger-icon"
              onClick={() => {
                handleToggle();
              }}
            />
            <div
              className={
                isOpen === true
                  ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open "
                  : "d-none"
              }
            >
              <div className="px-4 my-3">
                <Link to="#">Dashboard</Link>
                <Progress percent={50} status="actice" />
              </div>
              <div className="helgreyline"></div>
              <div className={mort}>
                <div className="sidecircle">
                  {location.pathname === "/heloc/lanlord/mortgageinfo" ? (
                    <FaCheckCircle className="checkicon" />
                  ) : null}
                </div>
                <div className="mort grey_color fw-500">Mortgage</div>
                <div></div>
              </div>

              <div className={pers}>
                <div className="sidecircle">
                  {location.pathname === "/personal_info" ? (
                    <>
                      <FaCheckCircle className="checkicon" />
                    </>
                  ) : null}
                </div>
                <div className="mort grey_color fw-500">Personal Info</div>
                <div></div>
              </div>

              <div className={cobo}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Co-Borrowers</div>
                <div></div>
              </div>

              <div className={inc}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Income</div>
                <div></div>
              </div>

              <div className={ass}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Assets</div>
                <div></div>
              </div>

              <div className={real}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Real State</div>
                <div></div>
              </div>

              <div className={Decl}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Declaration</div>
                <div></div>
              </div>

              <div className={demo}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Demographic</div>
                <div></div>
              </div>

              <div className={credit}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Credit</div>
                <div></div>
              </div>

              <div className={review}>
                <div className="sidecircle">
                  {/* <FaCheckCircle className="checkicon" /> */}
                </div>
                <div className="mort grey_color fw-500">Review and Submit</div>
                <div></div>
              </div>
            </div>
          </>
          <div
            className={
              isOpen === true
                ? "col-md-8 open he my-5 ps-lg-5"
                : isOpen === false
                ? "col-md-10 open nhi he my-5 ps-lg-5"
                : ""
            }
          >
            <div className="row">
              <div className="col-md-12">
                <div className=" form-label">
                  <h5>What type of property are you seeking to refinance?</h5>
                </div>
              </div>
              <div className="form-group mt-4 col-md-6">
                <select
                  className={`form-select py-2 ${
                    property === undefined ||
                    property === null ||
                    property === ""
                      ? "text-dark"
                      : "text-success"
                  }`}
                  value={property}
                  onChange={(e) => setProperty(e.target.value)}
                >
                  <option selected disabled>
                    Select Property Type
                  </option>
                  <option value={"Other"}>
                    {" "}
                    Other
                    {property === "Other" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'Manufactured Home'}> Manufactured Home 
                  {property === "Manufactured Home" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'Cooperative'}> Cooperative 
                  {property === "Cooperative" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'Condominium'}> Condominium 
                  {property === "Condominium" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'4Unit'}> 4 Unit 
                  {property === "4Unit" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'3Unit'}> 3 Unit
                  {property === "3Unit" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'2Unit'}> 2 Unit 
                  {property === "2Unit" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                  <option value={'Single Family'}> Single Family 
                  {property === "Single Family" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                  </option>
                </select>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12">
                <div className=" form-label">
                  <h5>How do you occupy the property?</h5>
                </div>
              </div>
              <div className="form-group mt-4 col-md-8">
                <div className="d-flex flex-wrap">
                <div className="me-0 me-md-3">
                    <div className="property_radio mx-auto">
                      <input
                        type="radio"
                        id="male"
                        name="type"
                        label={`Primary Residence ${
                          occupyProperty === "Primary Residence" ? "✔" : ""
                        }`}
                        className={`marrx404 ${
                          occupyProperty === "Primary Residence"
                            ? "text-success"
                            : ""
                        }`}
                        value="Primary Residence"
                        checked={
                          occupyProperty === "Primary Residence" ? true : false
                        }
                        onChange={(e) => setOccupyProperty(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="me-0 me-md-3">
                    <div className="property_radio mx-auto">
                      <input
                        label={`Investment ${
                          occupyProperty === "Investment" ? "✔" : ""
                        }`}
                        type="radio"
                        id="male"
                        name="type"
                        className={`marrx404 ${
                          occupyProperty === "Investment" ? "text-success" : ""
                        }`}
                        value="Investment"
                        checked={occupyProperty === "Investment" ? true : false}
                        onChange={(e) => setOccupyProperty(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="me-0 me-md-3">
                    <div className="property_radio mx-auto">
                      <input
                        label={`Second Home ${
                          occupyProperty === "Second Home" ? "✔" : ""
                        }`}
                        type="radio"
                        id="male"
                        name="type"
                        className={`marrx404 ${
                          occupyProperty === "Second Home" ? "text-success" : ""
                        }`}
                        value="Second Home"
                        checked={
                          occupyProperty === "Second Home" ? true : false
                        }
                        onChange={(e) => setOccupyProperty(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-3 d-flex align-items-baseline">
                    &nbsp; &nbsp;
                    <input type="checkbox" />
                    &nbsp; &nbsp;
                    <label className="h6 text-muted">
                      I might also operate my business here
                    </label>
                  </div>
                </div>

                <div className="row">
                  <h5 className="mt-4">
                    What is a comfortable monthly housing payment?
                  </h5>
                  <input
                    className="form-control text-capitalize  mt-4 dollar_input w-75"
                    type="number"
                    name=""
                    id=""
                  />
                </div>

                <div className="row">
                  <h5 className="mt-4">
                    What is your goal for refinancing your current mortgage?
                  </h5>

                  <select
                    name="refinancing_goal"
                    className={`form-select mt-3 w-75 py-2 ${
                      currentMortage === undefined ||
                      currentMortage === null ||
                      currentMortage === ""
                        ? "text-dark"
                        : "text-success"
                    }`}
                    value={currentMortage}
                    onChange={(e) => setCurrentMortage(e.target.value)}
                  >
                    <option selected disabled>
                      -Select Refinance Goal-
                    </option>
                    <option value={'Debt Consolidation'}> Debt Consolidation 
                    {currentMortage === "Debt Consolidation" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                    </option>
                    <option value={'Student Loan'}> Student Loan 
                    {currentMortage === "Student Loan" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                    </option>
                    <option value={'Home Improvement '}> Home Improvement 
                    {currentMortage === "Home Improvement " ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                    </option>
                    <option value={'Rate and Term Change'}> Rate and Term Change 
                    {currentMortage === "Rate and Term Change" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                    </option>
                    <option value={'Other'}> Other
                    {currentMortage === "Other" ? (
                      <span className="tick-mark2"> ✔</span>
                    ) : null}
                    </option>
                  </select>
                </div>

                <div className="row mt-5">
                  <h5>Where is the refinance property located?</h5>
                  <input
                    className="form-control text-capitalize  mt-3 w-75 mx-auto mx-lg-0"
                    type="number"
                    inputMode="decimal"
                    id=""
                    placeholder="Enter a location"
                  />
                </div>

                <div className="row mt-5 ">
                  <h5>
                    What is the current value of your property? Estimates are
                    fine.
                  </h5>
                  <input
                    className="form-control text-capitalize  mt-2 w-75"
                    type="number"
                    name=""
                    id=""
                  />
                </div>

                <div className="row mt-5 ">
                  <h5>What is the current balance of your mortgage?</h5>
                  <input
                    className="form-control text-capitalize  mt-2 w-75"
                    type="number"
                    name=""
                    id=""
                    placeholder="0"
                  />
                </div>

                <div className="row mt-4">
                  <label className="text-secondary h6">
                    Next is <span className="text-dark">Personal Info</span>
                  </label>
                </div>
                <button className="btn btn-primary rounded-0">
                  Save And Continue &nbsp;
                  <AiOutlineArrowRight />
                </button>
              </div>
            </div>
          </div>
          <ProfileInfo />
        </div>
      </div>
    </>
  );
};

export default LanMortageinfo;
