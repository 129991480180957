/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";

const libraries = ["places"];

const GoogleSearchTwo = ({ setaddress2 , address2 }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8", // Replace with your actual API key
    libraries,
  });

  const [addressDetails, setAddressDetails] = useState({});
  const searchBoxRef = useRef(null);

  useEffect(() => {
    setaddress2(addressDetails);
  }, [addressDetails, setaddress2]);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length === 0) {
      return;
    }

    const place = places[0];
    const addressComponents = place.address_components;

    const address = {
      street_number: "",
      street_name: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      apt_unit: "",
    };


    for (const component of addressComponents) {
      const types = component.types;
      if (types.includes("street_number")) {
        address.street_number = component.long_name;
      } else if (types.includes("route")) {
        address.street_name = component.long_name;
      } else if (types.includes("locality")) {
        address.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      } else if (types.includes("postal_code")) {
        address.postal_code = component.long_name;
      } else if (types.includes("country")) {
        address.country = component.long_name;
      } else if (types.includes("subpremise")) {
        address.apt_unit = component.long_name;
      }
    }

    setAddressDetails(address);
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <div>
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={onPlacesChanged}
      >
        <div className="position-relative">
          <input
            className="form-control text-capitalize  max-width-500 ps-5 pac-target-input req-color7 text-capitalize"
            type="text"
            placeholder="Enter Address"
          />
          {addressDetails?.city?.length > 4 ? (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                position: "absolute",
                color: "green",
                right: "30px",
                bottom: "-8px",
                zIndex: "123",
              }}
            >
              ✔
            </p>
          ) : null}
        </div>
      </StandaloneSearchBox>
    </div>
  );
};

export default GoogleSearchTwo;
