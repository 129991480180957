/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../Layout/Header";
import ProfileInfo from "../Profile/ProfileInfo";
import axios from "axios";
// import RefSideBar from "./RefSideBar";
import Baseurl from "../../../../Baseurl";
import Swal from "sweetalert2";
import { FaBars, FaCheckCircle, FaLock } from "react-icons/fa";
import { Progress } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
// import ReFooter from "./ReFooter";
import Footerx404 from "../../../../Images/Footerx404.png";

function RefCredit() {
  const location = useLocation();
  const mort =
    location.pathname === "/ref/mortageinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const pers =
    location.pathname === "/ref/personalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cobo =
    location.pathname === "/ref/Co-Borrowers"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/ref/income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const ass =
    location.pathname === "/ref/assets"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const real =
    location.pathname === "/ref/realstate"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const Decl =
    location.pathname === "/ref/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const demo =
    location.pathname === "/ref/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const credit =
    location.pathname === "/ref/credit"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const refreviewandsubmit =
    location.pathname === "/ref/refreviewandsubmit"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [fieldsError, setFieldsError] = useState();

  const [id, setId] = useState(null);
  const Assign_id = localStorage.getItem("assignId");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [socialSecurityNumber, setSocialSecurityNumber] = useState("");
  const [confirmSocialSecurityNumber, setConfirmSocialSecurityNumber] =
    useState("");
  const [estmatedCreditStore, setEstmatedCreditStore] = useState("");

  const getRefCreditInfo = async () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}mortgage/refinance/get/credit`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(false);
        console.log(response);
        const {
          id,
          dob,
          credit_score,
          social_security_no,
          c_social_security_no,
        } = response?.data?.data;
        setId(id);
        setDateOfBirth(dob);
        setEstmatedCreditStore(credit_score);
        setSocialSecurityNumber(social_security_no);
        setConfirmSocialSecurityNumber(c_social_security_no);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  useEffect(() => {
    getRefCreditInfo();
  }, []);

  const Data = new FormData();
  const SocialSecurityNumber = socialSecurityNumber?.replace(/\D/g, '');
  const ConfirmSocialSecurityNumber = confirmSocialSecurityNumber?.replace(/\D/g, '');
  Data.append("application_id", Assign_id);
  Data.append("dob", dateOfBirth);
  Data.append("ssn", SocialSecurityNumber);
  Data.append("cssn", ConfirmSocialSecurityNumber);
  Data.append("estimated_credit_score", estmatedCreditStore);
  if (id) {
    Data.append("id", id);
  }

  const onSubmit = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}mortgage/refinance/store/credit`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(true);
        console.log(response?.data?.data, "Data from Response");
        console.log("title:", response?.data?.data?.message);
        if (response.data.status === true) {
          setLoader(false);
          history.push("/ref/refreviewandsubmit");
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          Swal.fire({
            toast: true,
            icon: "error",
            title: response?.data?.error.map((e) => e),
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setFieldsError(error?.response?.data?.errors, "errors");
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        console.log(error);
      });

    // for (var pair of Data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
  };
  const [editing, steediting] = useState(true);
  function handleFormatNumber(event, state, type) {
    if (type === "ext") {
      steediting(false);
    } else {
      steediting(false);
    }
    const inputValue = event.target.value;
    // Remove all non-numeric characters from the input value
    const numericValue = inputValue?.replace(/[^0-9]/g, "");
    // Format the numeric value using toLocaleString
    const formattedValue = Number(numericValue)?.toLocaleString();
    state(formattedValue);
  }
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      <div className="container-fluid">
        <div className="row">
          <>
            <FaBars
              class=" none"
              onClick={() => {
                // props.OnHandleToggle();
                handleToggle();
              }}
            />

            <FaBars
              class=" block"
              id="topnav-hamburger-icon"
              onClick={() => {
                // props.OnHandleToggle();
                handleToggle();
              }}
            />
            <div
              className={
                isOpen === true
                  ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open "
                  : "d-none"
              }
            >
              <div className="px-4 my-3">
                <Link to="#">Dashboard</Link>
                <Progress percent={50} status="actice" />
              </div>
              <div className="refgreyline"></div>
              <Link to={"/ref/mortageinfo"}>
                <div className={mort}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/mortageinfo" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Mortgage</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/personalinfo"}>
                <div className={pers}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/personalinfo" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Personal Info</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/Co-Borrowers"}>
                <div className={cobo}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/Co-Borrowers" ? (
                      <>
                        <FaCheckCircle className="checkicon" />
                      </>
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Co-Borrowers</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/income"}>
                <div className={inc}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/income" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Income</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/assets"}>
                <div className={ass}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/assets" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}{" "}
                  </div>
                  <div className="mort grey_color fw-500">Assets</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/realstate"}>
                <div className={real}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/realstate" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Real State</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/declaration"}>
                <div className={Decl}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/declaration" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Declaration</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/demographic"}>
                <div className={demo}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/demographic" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Demographic</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/credit"}>
                <div className={credit}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/credit" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">Credit</div>
                  <div></div>
                </div>
              </Link>
              <Link to={"/ref/refreviewandsubmit"}>
                <div className={refreviewandsubmit}>
                  <div className="sidecircle">
                    {location.pathname === "/ref/refreviewandsubmit" ? (
                      <FaCheckCircle className="checkicon" />
                    ) : null}
                  </div>
                  <div className="mort grey_color fw-500">
                    Review and Submit
                  </div>
                  <div></div>
                </div>
              </Link>
              {/* <Link to={"/review"}>
          <div className={review}>
            <div className="sidecircle">
              {location.pathname === "/review" ? (
                <FaCheckCircle className="checkicon" />
              ) : null}
            </div>
            <div className="mort grey_color fw-500">Review and Submit</div>
            <div></div>
          </div>
        </Link> */}
            </div>
          </>

          <div
            className={
              isOpen === true
                ? "col-md-8 open he mt-5 mb-2 ps-lg-5"
                : isOpen === false
                ? "col-md-10 open nhi he mt-5 mb-2 ps-lg-5"
                : ""
            }
          >
            <div className="row p-5 Credit40">
              <div className="col-lg-10">
                {/* <h2 className="mt-4">CREDIT</h2> */}
                <span className="h5">What is your date of birth?</span>
                <div className="input-group mt-5 mb-4">
                  {/* <span className="input-group-text" id="basic-addon1">
                    What is your date of birth?
                  </span> */}
                  <input
                    type="date"
                    className="form-control text-capitalize  hinpt rounded-0 BgColors"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>
                {fieldsError?.dob
                  ? fieldsError?.dob.map((e) => (
                      <p className="text-danger">{e}</p>
                    ))
                  : null}
                <span class="h5">What is your Social Security Number?</span>
                <div class="input-group mt-5">
                  {/* <span class="input-group-text" id="basic-addon1">
                    What is your Social Security Number?
                  </span> */}
                  <input
                    type="text"
                    className="form-control text-capitalize  hinpt rounded-0 BgColors"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onBlur={() => steediting(true)}
                    onFocus={() => steediting(false)}
                    value={`${
                      socialSecurityNumber === undefined
                        ? 0
                        : socialSecurityNumber
                    }${editing === true ? ".00" : ""}`}
                    onChange={(e) =>
                      handleFormatNumber(
                        e,
                        setSocialSecurityNumber,
                        "socialSecurityNumber"
                      )
                    }
                  />
                </div>
                {fieldsError?.ssn
                  ? fieldsError?.ssn.map((e) => (
                      <p className="text-danger">{e}</p>
                    ))
                  : null}
                <p className="h6 my-2 d-flex mt-3 Fontchnage">
                  <p className="Luck404 ps-1">
                    <FaLock />
                  </p>{" "}
                  Your application and all personal information are safely
                  stored on secured encrypted servers
                </p>
                <span className="h5">Confirm Social Security Number</span>
                <div className="input-group mt-5">
                  <input
                    placeholder="Required"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) =>
                      handleFormatNumber(
                        e,
                        setConfirmSocialSecurityNumber,
                        "confirmSocialSecurityNumber"
                      )
                    }
                    className="form-control text-capitalize  text-capitalize123 m-0 ps-4 form-control text-capitalize rounded-0"
                    type="text"
                    onBlur={() => steediting(true)}
                    onFocus={() => steediting(false)}
                    value={`${
                      confirmSocialSecurityNumber === undefined
                        ? 0
                        : confirmSocialSecurityNumber
                    }${editing === true ? ".00" : ""}`}
                  />
                </div>
                {fieldsError?.cssn
                  ? fieldsError?.cssn.map((e) => (
                      <p className="text-danger">{e}</p>
                    ))
                  : null}
                <p className="h5 pt-3">What is your estimated credit score?</p>
                <select
                  class="form-select form-select mt-5 rounded-0 hinpt"
                  value={estmatedCreditStore}
                  onChange={(e) => setEstmatedCreditStore(e.target.value)}
                >
                  <option selected>SELECT CREDIT SCORE</option>
                  <option value="780+">780+</option>
                  <option value="740-799">740 - 799</option>
                  <option value="700-739">700 - 739</option>
                  <option value="600-699">600 - 699</option>
                  <option value="620-659">620 - 659</option>
                  <option value="Below 620">Below 620</option>
                </select>
                {fieldsError?.estimated_credit_score
                  ? fieldsError?.estimated_credit_score.map((e) => (
                      <p className="text-danger">{e}</p>
                    ))
                  : null}
                <label className="text-secondary h6 mt-4 rounded-0 ">
                  Next is <span className="text-dark">Review and Submit</span>
                </label>{" "}
                <br />
                <button
                  className="btn btn-primary rounded-0 mt-2"
                  onClick={onSubmit}
                >
                  Save And Continue &nbsp;
                  <AiOutlineArrowRight />
                </button>{" "}
              </div>
            </div>
            <div>
              <hr />
              <img src={Footerx404} alt="" width="100%" height="50%" />
            </div>
          </div>
          <ProfileInfo />
        </div>
      </div>
    </>
  );
}

export default RefCredit;
