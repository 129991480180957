import React, { useState } from "react";

export const Financial = ({ setFinalInstitute, value, fieldsError }) => {
  console.log(value);

  const { finalInstitute } = value;
  const { accountNumber } = value;
  const { numberOfShares } = value;

  const [editing, steediting] = useState(true);
  function handleChange(event, state, type) {
    console.log(event.target.value, "event.target.value");

    if (type === "finalInstitute") {
      steediting(false);
    } else {
      steediting(false);
    }
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = Number(numericValue).toLocaleString();
    console.log(formattedValue, "formattedValue");
    state({ ...value, [type]: formattedValue });
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col position-relative">
            <div className="mt-3">
              <h5 className="textdark">Financial Institution</h5>
              <input
                type="text"
                className="form-control text-capitalize "
                placeholder="Required"
                onChange={(e) => {
                  setFinalInstitute((perv) => ({
                    ...perv,
                    finalInstitute: e.target.value,
                  }));
                  handleChange(e, setFinalInstitute, "finalInstitute");
                }}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${finalInstitute === undefined ? 0 : finalInstitute}${
                  editing === true ? ".00" : ""
                }`}
              />
              {finalInstitute?.length < 3 ||
              finalInstitute === undefined ||
              finalInstitute === null ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "3%",
                    top: "54px",
                    color: "green",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
            {fieldsError?.financial_inst
              ? fieldsError?.financial_inst.map((e) => (
                  <p className="text-danger col-lg-12">{e}</p>
                ))
              : null}

            <div className="mt-3 position-relative">
              <h5 className="textdark">Account Number</h5>
              <input
                type="text"
                className="form-control text-capitalize "
                placeholder="Required"
                onChange={(e) => {
                  setFinalInstitute((perv) => ({
                    ...perv,
                    accountNumber: e.target.value,
                  }));
                  handleChange(e, setFinalInstitute, "accountNumber");
                }}
                onBlur={() => steediting(true)}
                onFocus={() => steediting(false)}
                value={`${accountNumber === undefined ? 0 : accountNumber}${
                  editing === true ? ".00" : ""
                }`}
              />
              {accountNumber?.length < 3 ||
              accountNumber === undefined ||
              accountNumber === null ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "1%",
                    top: "40px",
                    color: "green",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
            {fieldsError?.account_number
              ? fieldsError?.account_number.map((e) => (
                  <p className="text-danger col-lg-12">{e}</p>
                ))
              : null}
            <div className="mt-3 d-none position-relative">
              <h4>Number of shares</h4>
              <input
                type="text"
                className="form-control text-capitalize "
                placeholder="Required"
                value={numberOfShares}
                onChange={(e) =>
                  setFinalInstitute((perv) => ({
                    ...perv,
                    numberOfShares: e.target.value,
                  }))
                }
              />
              {numberOfShares?.length < 3 ||
              numberOfShares === undefined ||
              numberOfShares === null ? null : (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    right: "3%",
                    top: "55px",
                    color: "green",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Financial;
