/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {  useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Baseurl from "../../../Baseurl";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";

const UpdateProfile = () => {
  // const [fieldsError, setFieldsError] = useState();
  const [file, setFile] = useState(null);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  }

  const [loader, setLoader] = useState(false);
  const [array, SetArray] = useState();
  // const [bund, setBund] = useState("");
  const history = useHistory();

  const Data = new FormData();
  Data.append("first_name", array?.first_name);
  Data.append("last_name", array?.last_name);
  Data.append("email", array?.email);
  Data.append("phone", array?.phone);
  Data.append("file", file);
  console.log(file,"file")

  const onSubmit = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}update/borrower/profile/data`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setLoader(true);
        console.log(response?.data?.data, "Data from Response");
        console.log("title:", response?.data?.data?.message);
        if (response.data.status === true) {
          setLoader(false);

          history.push("/profile");
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          Swal.fire({
            toast: true,
            icon: "error",
            title: response?.data?.error.map((e) => e),
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        // setFieldsError(error?.response?.data?.errors, "errors");
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        console.log(error);
      });

    // for (var pair of Data.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
  };

  const Get_Profile = () => {
    setLoader(true);
    // console.log(bund2?.map((e)=>e),"bund2")
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}get/borrower/profile/data`,
      // data: BorrowerData2,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        SetArray(response?.data?.data);
        console.log(response?.data?.data, "get");

        console.log(response?.data?.data, "res");
        if (response?.data?.status === true) {
          setLoader(false);

          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        // setBund(error?.response?.data?.errors);
        console.log(error, "error");

        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  // console.log(bund?.address?.map((e,i)=>i),"bund")
  useEffect(() => {
    Get_Profile();
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}

      <Header />
      <section>
        <div className="container box_shadow mt-5" style={{ zIndex: "-1" }}>
          <div className="row">
            <div
              className="col-md-4 py-3"
              style={{ borderRight: "1px solid #dee2e6" }}
            >
              <div>
                <img
                  className="w-10 rounded-circle mx-auto d-block"
                  style={{width:"30%"}}
                  src={ Baseurl.imgurl + array?.image_path + "/" + array?.image}
                  // src={Profileimg}
                  alt=""
                />
                  {/* <input type="file" onChange={handleChange} class="form-control" /> */}
                  <div class="file-input text-center">
      <input
        type="file"
        name="file-input"
        id="file-input"
        class="file-input__input mx-4 primary"
        onChange={handleChange}
      />
      <label class="file-input__label" for="file-input">
       
        <span>Upload file</span></label
      >
    </div>

                <p className="text-secondary text-center my-2">
                  {array?.first_name}
                </p>
              </div>
            </div>
            <div className="col-md-8 py-3">
              <div>
                <div className="row mt-5 w-96 mx-auto">
                  <div className="col-md-6">
                    <input
                      className="form-control text-capitalize  py-2"
                      type="text"
                      id=""
                      defaultValue={array?.first_name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        SetArray((prevState) => ({
                          ...prevState,
                          first_name: newValue,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                  <input
                      className="form-control text-capitalize  py-2"
                      type="text"
                      id=""
                      defaultValue={array?.last_name}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        SetArray((prevState) => ({
                          ...prevState,
                          last_name: newValue,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row mt-5 w-96 mx-auto">
                  <div className="col-md-12">
                    <input
                      className="form-control text-capitalize  py-2"
                      type="email"
                      id=""
                      defaultValue={array?.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mt-5 w-96 mx-auto">
                  <div className="col-md-12">
                    <input
                      className="form-control text-capitalize  py-2"
                      type="number"
                      id=""
                      defaultValue={array?.phone}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="row mt-5 w-96 mx-auto">
                  <div className="col-md-12">
                    <input
                      className="form-control text-capitalize  py-2"
                      type="text"
                      id=""
                      defaultValue={array?.}
                    />
                  </div>
                </div> */}
                <div className="row mt-5">
                  <button
                    onClick={() => onSubmit()}
                    className="btn btn-primary mx-auto w-auto"
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default UpdateProfile;
