/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const libraries = ["places"];

const GoogleSerachThree = ({ setMultipleAddressData }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8", // Replace with your actual API key
    libraries,
  });

  const searchBoxRefs = useRef([]);
  const [multipleAddress, setMultipleAddress] = useState([
    { address: "", startDate: "", endDate: "" },
  ]);

  const handlePlusClick = () => {
    setMultipleAddress([
      ...multipleAddress,
      { address: "", startDate: "", endDate: "" },
    ]);
  };

  const handleMinusClick = (index) => {
    if (multipleAddress?.length > 1) {
      const newAddresses = [...multipleAddress];
      newAddresses.splice(index, 1);
      setMultipleAddress(newAddresses);
    }
  };

  const handleInputChange = (value, index, field) => {
    const newAddresses = [...multipleAddress];
    newAddresses[index][field] = value;
    setMultipleAddress(newAddresses);
  };

  const onPlacesChanged = (index) => {
    const places = searchBoxRefs.current[index].getPlaces();
    if (places.length === 0) {
      return;
    }

    const place = places[0];
    const addressComponents = place.address_components;
    const address = {
      street_number: "",
      street_name: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      apt_unit: "",
    };

    for (const component of addressComponents) {
      const types = component.types;
      if (types.includes("street_number")) {
        address.street_number = component.long_name;
      } else if (types.includes("route")) {
        address.street_name = component.long_name;
      } else if (types.includes("locality")) {
        address.city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      } else if (types.includes("postal_code")) {
        address.postal_code = component.long_name;
      } else if (types.includes("country")) {
        address.country = component.long_name;
      } else if (types.includes("subpremise")) {
        address.apt_unit = component.long_name;
      }
    }
    handleInputChange(address, index, "address");
  };

  useEffect(() => {
    setMultipleAddressData(multipleAddress);
  }, [multipleAddress, setMultipleAddressData]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps...</div>;

  return (
    <div>
      {multipleAddress?.map((address, index) => (
        <div key={index}>
          <label className="label font-sm font-sm1 clr mt-4">
            Please enter your previous address and specify the dates you lived
            there:
          </label>
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRefs.current[index] = ref)}
            onPlacesChanged={() => onPlacesChanged(index)}
          >
            <div className="iconss">
              <input
                className="form-control text-capitalize max-width-500 ps-5 pac-target-input mr req-color7 text-capitalize my-2"
                type="text"
                placeholder="Enter Address"
              />
              <i
                className="prefix lg fa fa-search icon-top iconss2"
                style={{ left: "20px" }}
              ></i>
              <i className="suffix lg fa fa-pencil pencil iconss7"></i>
              {address?.address?.city?.length > 4 ? (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    position: "absolute",
                    color: "green",
                    right: "30px",
                    bottom: "-8px",
                    zIndex: "123",
                  }}
                >
                  ✔
                </p>
              ) : null}
            </div>
          </StandaloneSearchBox>
          <div className="row">
            <div className="col-md-6 position-relative">
              <label className="label font-sm font-sm1 clr my-2">
                I lived at this address from Start Date.
                <br />
              </label>
              <div>
                <DatePicker
                  selected={address?.startDate}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="form-control my-2 w-100"
                  showFullMonthYearPicker
                  onChange={(date) =>
                    handleInputChange(date, index, "startDate")
                  }
                />
              </div>
              {address?.startDate &&
              address?.startDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
              })?.length > 4 ? (
                <>
                  {" "}
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "8%",
                      bottom: "0px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                </>
              ) : null}
            </div>
            <div className="col-md-6 position-relative">
              <label className="label font-sm font-sm1 clr my-2">
                I lived at this address from End Date.
                <br />
              </label>
              <div>
                <DatePicker
                  selected={address?.endDate}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="form-control my-2 w-100"
                  onChange={(date) => handleInputChange(date, index, "endDate")}
                />
              </div>
              {address?.endDate &&
              address?.endDate?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
              })?.length > 4 ? (
                <>
                  {" "}
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      position: "absolute",
                      color: "green",
                      right: "24px",
                      bottom: "0px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-6">
            {multipleAddress?.length > 1 && (
              <button
                className="btn btn-danger"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "20px",
                }}
                onClick={() => handleMinusClick(index)}
              >
                Remove &nbsp;
                <MdDelete />
              </button>
            )}
          </div>
        </div>
      ))}
      <div
        className="col-md-12"
        style={{
          display: "flex",
          justifyContent: "start",
          marginBottom: "30px",
        }}
      >
        <button
          type="button"
          className="btn btn-primary"
          onClick={handlePlusClick}
        >
          Add &nbsp;
          <FaPlus />
        </button>
      </div>
    </div>
  );
};

export default GoogleSerachThree;
