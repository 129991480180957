/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import Header from "../../../../Layout/Header";
import ProfileInfo from "../../Profile/ProfileInfo";
import Swal from "sweetalert2";
import axios from "axios";
import Baseurl from "../../../../../Baseurl";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FaCheckCircle, FaBars } from "react-icons/fa";
import { Progress } from "antd";

const TanAdditionalInfo = () => {
  const [loader, setLoader] = useState(false);
  const [allPostData, setAllPostData] = useState();
  const [bund, setBund] = useState("");

  const application_id = localStorage.getItem("assignId");
  const reviewData = new FormData();
  reviewData.append("application_id", application_id);

  const location = useLocation();
  const pers =
    location.pathname === "/heloc/tanent/personalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100 ";
  const addit =
    location.pathname === "/heloc/tanent/additionalinfo"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const cosi =
    location.pathname === "/heloc/tanent/Co-Borrowers"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const inc =
    location.pathname === "/heloc/tanent/Income"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const back =
    location.pathname === "/heloc/tanent/background"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const demo =
    location.pathname === "/heloc/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";
  const docu =
    location.pathname === "/heloc/tanent/declaration"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const review =
    location.pathname === "/heloc/tanent/demographic"
      ? "sidecolheight d-flex justify-content-around align-items-center w-100 sidecirclemain"
      : "sidecolheight d-flex justify-content-around align-items-center w-100";

  const history = useHistory();

  const postData = () => {
    const token = localStorage.getItem("usertoken");

    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/progress`,
      data: reviewData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setAllPostData(response?.data?.data);
        console.log(response?.data?.data, "sidebar response");
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          // history.push('/new_mortage')
          setLoader(false);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
        // console.log(allGet, "all data");
        console.log(response, "my response");
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
    if (isOpen === true) {
      setIsOpen(false);
    } else {
      console.log(isOpen, "hui");
    }
    console.log(isOpen, "huihui");
  };

  const Assign_id = localStorage.getItem("assignId");
  const [showfirstform, setshowfirstform] = useState(false);
  const [data, setdata] = useState("");
  const [getData, setGetData] = useState("");

  // const [property, setProperty] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [pet_living, setpet_living] = useState(0);
  const [smoke, setsmoke] = useState(0);
  const [type, settype] = useState("");
  const [breed, setbreed] = useState("");
  const [weight, setweight] = useState("");
  const [age, setage] = useState();
  const [make, setmake] = useState("");
  const [model, setmodel] = useState();
  const [year, setyear] = useState();
  const [color, setcolor] = useState();
  const [id, Setid] = useState();
  const [fireArms, setFireArms] = useState("");
  const [fireArmsTF, setFireArmsTF] = useState(0);

  const [smokingArea, setSmokingArea] = useState("");
  const [smokingType, setSmokingType] = useState("");

  const [veicle_image, setVehicleImage] = useState([]);
  const [pet_image, setPetImage] = useState([]);

  console.log(smoke, "smoke");

  const Data = new FormData();
  Data.append("application_id", Assign_id);
  Data.append("pet_living", pet_living);
  Data.append("vehicle", vehicle);
  Data.append("smoke", smoke == 0 ? "no" : "yes");
  Data.append("smoking_area", smokingArea);
  Data.append("smoking_type", smokingType);
  Data.append("type", type);
  Data.append("breed", breed);
  Data.append("weight", weight);
  Data.append("age", age);
  Data.append("make", make);
  Data.append("model", model);
  Data.append("year", year);
  Data.append("color", color);
  Data.append("vehicle_image", veicle_image);
  Data.append("pet_image", pet_image);
  Data.append("firearms", fireArmsTF ? 1 : 0);
  Data.append("firearms_type", fireArms);
  id && Data.append("id", id);

  const Get_Borrower = () => {
    setLoader(true);
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/get/additional/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data?.data);
        setGetData(response?.data?.data);

        if (response?.data?.status === true) {
          setpet_living(
            response?.data?.data?.pet_living == null
              ? 0
              : response?.data?.data?.pet_living
          );
          setVehicle(
            response?.data?.data?.vehicle == null
              ? 0
              : response?.data?.data?.vehicle
          );
          // setsmoke(response?.data?.data?.smoke);
          settype(response?.data?.data?.type);
          setbreed(response?.data?.data?.breed);
          setweight(response?.data?.data?.weight);
          setage(response?.data?.data?.age);
          setmake(response?.data?.data?.make);
          setmodel(response?.data?.data?.model);
          setyear(response?.data?.data?.year);
          setcolor(response?.data?.data?.color);
          setweight(response?.data?.data?.weight);
          setweight(response?.data?.data?.weight);
          Setid(response?.data?.data?.id);
          setLoader(false);

          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    Get_Borrower();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const AddAdditionalInfo = () => {
    setLoader(true);
    // alert();
    let token = localStorage.getItem("usertoken");
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}real-estate/rent/tenant/store/additional/info`,
      data: Data,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setdata(response?.data?.message);
        if (response?.data?.status === true) {
          console.log(response?.data?.message, "response?.data?.message");
          setshowfirstform(false);
          setLoader(false);
          Get_Borrower();
          Swal.fire({
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          history.push("/heloc/tanent/cosigner");
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setBund(error?.response?.data?.errors);
        Swal.fire({
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <Header />
      {!loader ? (
        <div className="container-fluid">
          <div className="row">
            <>
              <FaBars
                class=" none"
                onClick={() => {
                  handleToggle();
                }}
              />

              <FaBars
                class=" block"
                id="topnav-hamburger-icon"
                onClick={() => {
                  handleToggle();
                }}
              />
              <div
                className={
                  isOpen === true
                    ? "col-md-2 ps-0 sidebarmain fixed_side sidebar-nav open "
                    : "d-none"
                }
              >
                <div className="px-4 my-3">
                  <Link to="#">Dashboard</Link>
                  <br />
                  <span>Tanent</span>
                  <Progress percent={allPostData} status="actice" />
                </div>
                <div className="tangreyline"></div>'
                <Link to="/heloc/tanent/personalinfo">
                  <div className={pers}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/personalinfo" ? (
                        <FaCheckCircle className="checkicon" />
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">Personal Info</div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/additionalinfo"}>
                  <div className={addit}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/additionalinfo" ? (
                        <>
                          <FaCheckCircle className="checkicon" />
                        </>
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">
                      Additional Info
                    </div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/cosigner"}>
                  <div className={cosi}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/cosigner" ? (
                        <FaCheckCircle className="checkicon" />
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">Co Singers</div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/Income"}>
                  <div className={inc}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/Income" ? (
                        <>
                          <FaCheckCircle className="checkicon" />
                        </>
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">Income</div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/background"}>
                  <div className={back}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/background" ? (
                        <FaCheckCircle className="checkicon" />
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">Background</div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/demographic"}>
                  <div className={demo}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/demographic" ? (
                        <FaCheckCircle className="checkicon" />
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">Demographic</div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/document"}>
                  <div className={docu}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/document" ? (
                        <FaCheckCircle className="checkicon" />
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">Document</div>
                    <div></div>
                  </div>
                </Link>
                <Link to={"/heloc/tanent/review"}>
                  <div className={review}>
                    <div className="sidecircle">
                      {location.pathname === "/heloc/tanent/review" ? (
                        <FaCheckCircle className="checkicon" />
                      ) : null}
                    </div>
                    <div className="mort grey_color fw-500">
                      Review And Submit
                    </div>
                    <div></div>
                  </div>
                </Link>
              </div>
            </>
            <div
              className={
                isOpen === true
                  ? "col-md-8 open he my-5 ps-lg-5"
                  : isOpen === false
                  ? "col-md-10 open nhi he my-5 ps-lg-5"
                  : ""
              }
            >
              <div className="row">
                <h3 className="text-dark3">
                  We have a few more questions for you. Almost done!
                </h3>
                <h5 className="text-dark">General Information</h5>

                <div className="row mt-2">
                  <h5 className="form-label ">
                    1) Will you have a pet living with you at this property?
                  </h5>
                  <div className="col-3 col-md-2 col-lg-1 mt-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="q1"
                      id="YES"
                      autocomplete="off"
                      checked={pet_living == 1}
                      onChange={() => setpet_living(1)}
                    />
                    <label className="btn btn-link yes px-3 py-2" for="YES">
                      Yes
                    </label>
                  </div>
                  <div className="col-3 col-md-2 col-lg-1 mt-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="q1"
                      id="No"
                      autocomplete="off"
                      defaultChecked={pet_living == 0}
                      onChange={() => setpet_living(0)}
                    />
                    <label className="btn btn-link no px-3 py-2" for="No">
                      No
                    </label>
                  </div>

                  {pet_living == 1 ? (
                    <>
                      <div className="row mt-3 mb-4" style={{ gap: "20px" }}>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Type (DOG, CAT, ETC.)
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="text"
                            name="type"
                            placeholder="Type (DOG, CAT, ETC.)"
                            defaultValue={type}
                            onChange={(e) => settype(e.target.value)}
                          />
                          {type?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          <small
                            className="text-danger fpr"
                            id="type_error"
                          ></small>
                        </div>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Breed
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            name="breed"
                            type="text"
                            placeholder="Breed"
                            defaultValue={breed}
                            onChange={(e) => {
                              setbreed(e.target.value);
                            }}
                          />
                          {breed?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          <small
                            className="text-danger fpr"
                            id="breed_error"
                          ></small>
                        </div>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Weigth (LBS)
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            name="weight"
                            step="any"
                            type="number"
                            placeholder="Weigth (LBS)"
                            defaultValue={weight}
                            onChange={(e) => setweight(e.target.value)}
                          />
                          {weight?.length >= 2 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          <small
                            className="text-danger fpr"
                            id="weight_error"
                          ></small>
                        </div>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Age
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            name="age"
                            step="any"
                            type="number"
                            placeholder="Age"
                            defaultValue={age}
                            onChange={(e) => setage(e.target.value)}
                          />
                          {age?.length >= 2 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          <small
                            className="text-danger fpr"
                            id="age_error"
                          ></small>
                        </div>
                        <div className="col-md-6 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Pet Image
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="file"
                            onChange={(e) => setVehicleImage(e.target.files[0])}
                          />
                          {bund?.vehicle_image
                            ? bund?.vehicle_image?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                          <small
                            className="text-danger fpr"
                            id="age_error"
                          ></small>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="row mt-3">
                  <p className="h5">
                    2) Will you have a vehicle at this property?
                  </p>
                  <div className="col-3 col-md-2 col-lg-1 mt-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="q2"
                      id="YESs"
                      autocomplete="off"
                      defaultChecked={vehicle == 1}
                      onChange={() => setVehicle(1)}
                    />
                    <label className="btn btn-link yes px-3 py-2" for="YESs">
                      Yes
                    </label>
                  </div>
                  <div className="col-3 col-md-2 col-lg-1 mt-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="q2"
                      id="Noos"
                      autocomplete="off"
                      defaultChecked={vehicle == 0}
                      onChange={() => setVehicle(0)}
                    />
                    <label className="btn btn-link no px-3 py-2" for="Noos">
                      No
                    </label>
                  </div>

                  {vehicle == 1 ? (
                    <>
                      <div className="row mt-2 mb-4" style={{ gap: "20px" }}>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Make
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="text"
                            name="make"
                            defaultValue={make}
                            placeholder="Make"
                            onChange={(e) => {
                              setmake(e.target.value);
                            }}
                          />
                          {make?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Model
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="number"
                            name="model"
                            defaultValue={model}
                            placeholder="Model"
                            onChange={(e) => setmodel(e.target.value)}
                          />
                          {model?.length >= 2 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.model
                            ? bund?.model?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Color
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="text"
                            name="color"
                            defaultValue={color}
                            placeholder="Color"
                            onChange={(e) => setcolor(e.target.value)}
                          />
                          {color?.length > 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-3 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Year
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="number"
                            name="year"
                            placeholder="Year"
                            defaultValue={year}
                            onChange={(e) => setyear(e.target.value)}
                          />
                          {year?.length >= 4 ? (
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                color: "green",
                                right: "24px",
                                bottom: "-8px",
                                zIndex: "123",
                              }}
                            >
                              ✔
                            </p>
                          ) : null}
                          {bund?.year
                            ? bund?.year?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                        </div>
                        <div className="col-md-6 p-md-0 position-relative">
                          <label
                            className="d-block mt-2 mt-lg-1"
                            style={{ fontSize: "11px" }}
                          >
                            Vehicle Image
                          </label>
                          <input
                            className="w-100 add_input px-2 form-control"
                            type="file"
                            onChange={(e) => setPetImage(e.target.files[0])}
                          />
                          {bund?.pet_image
                            ? bund?.pet_image?.map((e) => (
                                <p className="text-danger">{e}</p>
                              ))
                            : null}
                          <small
                            className="text-danger fpr"
                            id="age_error"
                          ></small>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="row mt-3">
                  <p className="h5">3) Do you smoke?</p>
                  <div className="d-flex flex-wrap">
                    <div className="col-3 col-md-2 col-lg-1 mt-2">
                      <input
                        type="radio"
                        className="btn-check"
                        name="q22"
                        id="YESsss"
                        autocomplete="off"
                        checked={smoke == 1}
                        onChange={() => setsmoke(1)}
                      />
                      <label
                        className="btn btn-link yes px-3 py-2"
                        for="YESsss"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-3 col-md-2 col-lg-1 mt-2">
                      <input
                        type="radio"
                        className="btn-check"
                        name="q22"
                        id="Nooss"
                        autocomplete="off"
                        defaultChecked={smoke == 0}
                        onChange={() => setsmoke(0)}
                      />
                      <label className="btn btn-link no px-3 py-2" for="Nooss">
                        No
                      </label>
                    </div>
                  </div>
                  {smoke ? (
                    <>
                      <div className="d-flex flex-wrap">
                        <label className="me-3 mt-4 usm">
                          Type Of Smoking:
                        </label>
                        <label className="me-3 mt-2 usm">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Cigarettes"
                            autocomplete="off"
                            value="Cigarettes Cigars"
                            checked={smokingType == "Cigarettes Cigars"}
                            onChange={(e) => setSmokingType(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                            for="Cigarettes"
                          >
                            Cigarettes Cigars{" "}
                            {smokingType == "Cigarettes Cigars" ? "✔" : null}
                          </span>
                        </label>
                        <label className="me-3 mt-2 usm">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Cigarettes"
                            autocomplete="off"
                            value="Vapes"
                            checked={smokingType == "Vapes"}
                            onChange={(e) => setSmokingType(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                            for="Cigarettes"
                          >
                            Vapes {smokingType == "Vapes" ? "✔" : null}
                          </span>
                        </label>
                        <label className="me-3 mt-2 usm">
                          <input
                            type="radio"
                            className="btn-check"
                            name="Cigarettes"
                            autocomplete="off"
                            value="Marijuana & others"
                            checked={smokingType == "Marijuana & others"}
                            onChange={(e) => setSmokingType(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100"
                            for="Cigarettes"
                          >
                            Marijuana & others{" "}
                            {smokingType == "Marijuana & others" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="d-flex flex-wrap">
                        <label className="me-3 mt-4 usm">
                          Area Of Smoking:
                        </label>
                        <label className="me-3 mt-2 usm">
                          <input
                            type="radio"
                            className="btn-check"
                            name="q22"
                            autocomplete="off"
                            value="Outdoor Only"
                            checked={smokingArea == "Outdoor Only"}
                            onChange={(e) => setSmokingArea(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                            id="outdoorbtn12"
                            for="out"
                          >
                            Outdoor Only{" "}
                            {smokingArea == "Outdoor Only" ? "✔" : null}
                          </span>
                        </label>
                        <label className="me-3 mt-2 usm">
                          <input
                            type="radio"
                            className="btn-check"
                            name="q22"
                            autocomplete="off"
                            value="Indoors"
                            checked={smokingArea == "Indoors"}
                            onChange={(e) => setSmokingArea(e.target.value)}
                          />
                          <span
                            className="btn btn btn-link yes2 px-3 py-2 w-100"
                            id="outdoorbtn12"
                            for="out"
                          >
                            Indoors {smokingArea == "Indoors" ? "✔" : null}
                          </span>
                        </label>
                      </div>
                      <div className="d-flex">
                        <p className="h5 mt-4">
                          Smoking is only allowed outside on the street
                        </p>
                      </div>
                    </>
                  ) : null}

                  {bund?.smoke
                    ? bund?.smoke?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                  {bund?.smoking_area
                    ? bund?.smoking_area?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div className="row mt-3">
                  <p className="h5">
                    4) Do you store/handle/own any type of firearms /
                    Explosives?
                  </p>
                  <div className="row mt-3">
                    <div className="col-3 col-md-2 col-lg-1 mt-2">
                      <input
                        type="radio"
                        className="btn-check"
                        name="fire"
                        id="fireYes"
                        autocomplete="off"
                        checked={fireArmsTF == 1}
                        onChange={() => setFireArmsTF(1)}
                      />
                      <label
                        className="btn btn-link yes px-3 py-2"
                        for="fireYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="col-3 col-md-2 col-lg-1 mt-2">
                      <input
                        type="radio"
                        className="btn-check"
                        name="fire"
                        id="fireNo"
                        autocomplete="off"
                        defaultChecked={fireArmsTF == 0}
                        onChange={() => setFireArmsTF(0)}
                      />
                      <label className="btn btn-link no px-3 py-2" for="fireNo">
                        No
                      </label>
                    </div>
                  </div>
                  {fireArmsTF == 1 ? (
                    <div className="d-flex flex-wrap">
                      <label className="me-3 mt-2 usm">
                        <input
                          type="radio"
                          className="btn-check"
                          name="fireArmsTF"
                          autocomplete="off"
                          value="Type in what type of fire arms"
                          checked={fireArms == "Type in what type of fire arms"}
                          onChange={(e) => setFireArms(e.target.value)}
                        />
                        <span
                          className="btn btn btn-link yes2 px-3 py-2 w-100 ms-0"
                          for="fireArmsTF"
                        >
                          Type in what type of fire arms{" "}
                          {fireArms == "Type in what type of fire arms"
                            ? "✔"
                            : null}
                        </span>
                      </label>
                      <label className="me-3 mt-2 usm">
                        <input
                          type="radio"
                          className="btn-check"
                          name="fireArmsTF"
                          autocomplete="off"
                          value="Personal Use"
                          checked={fireArms == "Personal Use"}
                          onChange={(e) => setFireArms(e.target.value)}
                        />
                        <span
                          className="btn btn btn-link yes2 px-3 py-2 ms-0"
                          for="fireArmsTF"
                        >
                          Personal Use {fireArms == "Personal Use" ? "✔" : null}
                        </span>
                      </label>
                      <label className="me-3 mt-2 usm">
                        <input
                          type="radio"
                          className="btn-check"
                          name="fireArmsTF"
                          autocomplete="off"
                          value="Pleasure"
                          checked={fireArms == "Pleasure"}
                          onChange={(e) => setFireArms(e.target.value)}
                        />
                        <span
                          className="btn btn btn-link yes2 px-3 py-2 ms-0"
                          for="fireArmsTF"
                        >
                          Pleasure {fireArms == "Pleasure" ? "✔" : null}
                        </span>
                      </label>
                      <label className="me-3 mt-2 usm">
                        <input
                          type="radio"
                          className="btn-check"
                          name="fireArmsTF"
                          autocomplete="off"
                          value="Hunting"
                          checked={fireArms == "Hunting"}
                          onChange={(e) => setFireArms(e.target.value)}
                        />
                        <span
                          className="btn btn btn-link yes2 px-3 py-2 ms-0"
                          for="fireArmsTF"
                        >
                          Hunting {fireArms == "Hunting" ? "✔" : null}
                        </span>
                      </label>
                      <label className="me-3 mt-2 usm">
                        <input
                          type="radio"
                          className="btn-check"
                          name="fireArmsTF"
                          autocomplete="off"
                          value="for work"
                          checked={fireArms == "for work"}
                          onChange={(e) => setFireArms(e.target.value)}
                        />
                        <span
                          className="btn btn btn-link yes2 px-3 py-2 ms-0"
                          for="fireArmsTF"
                        >
                          for work {fireArms == "for work" ? "✔" : null}
                        </span>
                      </label>
                    </div>
                  ) : null}
                  {bund?.firearms
                    ? bund?.firearms?.map((e) => (
                        <p className="text-danger">{e}</p>
                      ))
                    : null}
                </div>

                <div className="mt-5">
                  <label className="text-scolor fontsbtn text-center">
                    Next is <span className="fontsbtncobor">Co-Signers</span>
                  </label>
                  <br />
                  <button
                    id="btn-save"
                    className="btn btn-primary rounded mt-2 btnn-us1 font-bold"
                    onClick={AddAdditionalInfo}
                  >
                    Save & Continue &nbsp;
                    <AiOutlineArrowRight />
                  </button>
                </div>
              </div>
            </div>
            <ProfileInfo />
            <div className="footerimage3">
              <img
                src="https://bankerbroker.developer-oa.xyz/assets/img/footercity.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TanAdditionalInfo;
